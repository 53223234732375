import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Separator } from '@/components/ui/separator'
import Spinner from '@/components/ui/Spinner'
import { usePresets } from '@/hooks/queries/usePresets'
import {
  companySettingsSchema,
  ICompany,
  ICompanySettings
} from '@/types/company.type'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { TbPencil, TbRefresh } from 'react-icons/tb'
import { useRestorePresets } from '../../hooks/useRestorePresets'
import { useUpdateCompanySettings } from '../../hooks/useUpdateCompanySettings'
import PresetsTable from './PresetsTable'

export interface IImportTabProps {
  company: ICompany
}

const ImportTab: FC<IImportTabProps> = (props: IImportTabProps) => {
  const presets = usePresets()

  const tableData = useMemo(
    () =>
      presets.data
        ? Object.entries(presets.data).map(([key, value]) => ({
            name: key,
            data: value
          }))
        : [],
    [presets.data]
  )

  const { mutate: restorePresets } = useRestorePresets()

  const form = useForm<ICompanySettings>({
    resolver: yupResolver(
      companySettingsSchema.pick([
        'importPickupAdjustment',
        'importDropoffAdjustment'
      ])
    )
  })

  const { mutate: submit } = useUpdateCompanySettings()

  useEffect(() => form.reset(props.company.settings), [props.company])

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(data => submit(data))}>
        <Card>
          <CardHeader>
            <CardTitle>Import</CardTitle>
            <CardDescription>
              Manage Import settings and presets.
            </CardDescription>
            <Separator />
          </CardHeader>
          <CardContent className='flex flex-col gap-8'>
            <Card>
              <CardHeader>
                <CardTitle>Settings</CardTitle>
                <CardDescription>
                  Import settings. Each field is represented in minutes.
                </CardDescription>
              </CardHeader>
              <CardContent className='flex flex-col gap-4 max-w-[700px]'>
                <FormField
                  control={form.control}
                  name='importPickupAdjustment'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Pickup Adjustment</FormLabel>
                      <FormDescription>
                        If the Pickup Time value in your import file is empty
                        but the Dropoff Time exists, the import provider will
                        try to set the Pickup Time relative to the Dropoff Time
                        using this adjustment.
                      </FormDescription>
                      <FormControl>
                        <Input className='max-w-80 h-8' {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name='importDropoffAdjustment'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Dropoff Adjustment</FormLabel>
                      <FormDescription>
                        If the Dropoff Time value in your import file is empty
                        but the Pickup Time exists, the import provider will try
                        to set the Dropoff Time relative to the Pickup Time
                        using this adjustment.
                      </FormDescription>
                      <FormControl>
                        <Input className='max-w-80 h-8' {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CardContent>
              <CardFooter>
                <Button className='w-64 h-8' type='submit'>
                  <TbPencil className='mr-1' />
                  Save changes
                </Button>
              </CardFooter>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Presets</CardTitle>
                <CardDescription>
                  Import presets. You can create or edit presets when you're
                  importing a file.
                </CardDescription>
              </CardHeader>
              <CardContent className='flex flex-col gap-4'>
                {presets.isLoading || !presets.data ? (
                  <Spinner />
                ) : (
                  <PresetsTable data={tableData} />
                )}
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button variant='destructive' className='w-32'>
                      <TbRefresh className='mr-2' />
                      Restore
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                      <AlertDialogDescription>
                        This action cannot be undone. It will restore presets to
                        factory settings. All basic presets will be returned,
                        and custom presets will be deleted.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                      <AlertDialogAction onClick={() => restorePresets()}>
                        Continue
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </CardContent>
            </Card>
          </CardContent>
        </Card>
      </form>
    </Form>
  )
}
export default ImportTab
