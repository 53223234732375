import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/ui/command'
import { Label } from '@/components/ui/label'
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover'
import { useBroker } from '@/hooks/queries/useBroker'
import { useBrokers } from '@/hooks/queries/useBrokers'
import { cn } from '@/lib/utils'
import { IBroker } from '@/types/brokers.type'
import { FC, useState } from 'react'
import { TbCheck, TbSearch } from 'react-icons/tb'

interface IBrokerInputProps {
  value: number
  title?: string
  id?: string
  required?: boolean
  fieldClassName?: string
  popoverClassName?: string
  disabled?: boolean
  onChange?: (value: IBroker) => any
}

const BrokerInput: FC<IBrokerInputProps> = (props: IBrokerInputProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const broker = useBroker(props.value)

  const { data: brokers } = useBrokers()

  return (
    <div className={cn('flex flex-col gap-2')}>
      {props.title && (
        <Label htmlFor={props.id}>
          {props.title}
          {props.required && ' *'}
        </Label>
      )}
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant='outline'
            role='combobox'
            className={cn('h-8 w-64 justify-between', props.fieldClassName)}
            aria-expanded={open}
          >
            <span className='overflow-hidden whitespace-nowrap text-ellipsis'>
              {broker.data ? broker.data.name : 'Search for brokers...'}
            </span>
            <TbSearch />
          </Button>
        </PopoverTrigger>
        <PopoverContent className={cn('w-64 p-0', props.popoverClassName)}>
          <Command>
            <CommandInput placeholder='Search for brokers...' />
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandList>
              <CommandGroup>
                {brokers?.map(broker => (
                  <CommandItem
                    key={broker.id}
                    value={String(broker.name)}
                    onSelect={() => {
                      props.onChange?.(broker)
                      setOpen(false)
                    }}
                  >
                    <TbCheck
                      className={`w-4 h-4 mr-2 ${props.value === broker.id ? 'opacity-100' : 'opacity-0'}`}
                    />
                    {broker.name}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default BrokerInput
