import { yupTransformer } from '@/lib/yup'
import { array, date, number, object, ObjectSchema } from 'yup'
import { ITimeRange, timeRangeSchema } from './common.type'
import { IDriver } from './drivers.type'
import { IVehicle } from './vehicles.type'

export interface IShift {
  id: number
  date: Date
  driver: IDriver
  driverId: number
  vehicle: IVehicle
  vehicleId: number
  companyId: number
  started: boolean
  breaks: ITimeRange[]
  workingTime: ITimeRange
}

export interface ISchemaShift
  extends Omit<IShift, 'id' | 'driver' | 'vehicle' | 'companyId' | 'started'> {}

export const shiftSchema: ObjectSchema<ISchemaShift> = object({
  date: date().required('Date is required.'),
  driverId: number().required('Driver is required.'),
  vehicleId: number().required('Vehicle is required.'),
  breaks: array()
    .of(timeRangeSchema)
    .max(5, 'Maximum 5 breaks allowed.')
    .required('Breaks are required.'),
  workingTime: timeRangeSchema.required('Working time is required.')
}).transform(yupTransformer)
