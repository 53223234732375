import ScheduleStatusBadge from '@/components/tables/TripsTable/ScheduleStatusBadge'
import { ITrip } from '@/types/trips.type'
import { FC } from 'react'
import { TbPencil } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import AddressLabel from '../address-label'
import { Button } from '../button'
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../hover-card'
import TimePreview from './TimePreview'

interface ITripPreviewProps {
  trip: ITrip
  children?: React.ReactNode
}

const TripPreview: FC<ITripPreviewProps> = (props: ITripPreviewProps) => {
  const navigate = useNavigate()

  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        {props.children || props.trip.id}
      </HoverCardTrigger>
      <HoverCardContent className='flex flex-row gap-4 w-96'>
        <div className='flex flex-col gap-8'>
          {props.trip.schedules.map((schedule, index) => (
            <div className='flex flex-col gap-2 text-sm'>
              <div className='flex flex-row gap-2'>
                <span>
                  Rider: {schedule.rider.firstName} {schedule.rider.middleName}{' '}
                  {schedule.rider.lastName}{' '}
                  {schedule.guests!?.length > 0 &&
                    `(${schedule.guests?.length} guests)`}
                </span>
                <ScheduleStatusBadge value={schedule.status} />
              </div>
              <span>Mobility: {schedule.mobility}</span>
              {schedule.notes && <span>Notes: {schedule.notes}</span>}
              <div className='flex flex-row gap-2'>
                <div className='flex flex-col gap-0 place-items-center'>
                  <div className='w-2 h-2 rounded-full border-primary border' />
                  <div className='h-32 w-[0.1px] bg-primary' />
                  <div className='w-2 h-2 rounded-full border-primary border' />
                </div>
                <div className='flex flex-col justify-between'>
                  <div className='flex flex-col gap-1'>
                    <TimePreview
                      value={schedule}
                      time='pickup'
                      className='flex flex-row gap-1'
                    />
                    <AddressLabel value={schedule.from} />
                  </div>
                  <div className='flex flex-col gap-1'>
                    <AddressLabel value={schedule.to} />
                    <TimePreview
                      value={schedule}
                      time='dropoff'
                      className='flex flex-row gap-1'
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Button
          className='rounded-full min-w-8 h-8'
          size='icon'
          onClick={() => navigate(`/trips/${props.trip.id}`)}
        >
          <TbPencil />
        </Button>
      </HoverCardContent>
    </HoverCard>
  )
}
export default TripPreview

