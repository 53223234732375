import { useEffect } from 'react'
import { PanelGroupStorage } from 'react-resizable-panels'
import { Outlet, useNavigate } from 'react-router-dom'
import SideBar from './components/layout/sideBar/SideBar'
import TopBar from './components/layout/topBar/TopBar'
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup
} from './components/ui/resizable'
import { useAppSelector } from './hooks/useAppSelector'
import ErrorPage from './pages/ErrorPage'

const Layout = () => {
  const isAuthorized = useAppSelector(state => state.authState.authorized)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthorized) navigate('/login')
  })

  const panelGroupStorage: PanelGroupStorage = {
    getItem: (name: string) => {
      name = name.replaceAll('react-resizable-panels', 'layout')
      return localStorage.getItem(name)
    },
    setItem: (name: string, value: string) => {
      name = name.replaceAll('react-resizable-panels', 'layout')
      return localStorage.setItem(name, value)
    }
  }

  return isAuthorized ? (
    <div className='flex flex-row h-screen w-screen overflow-x-hidden overflow-y-hidden gap-0'>
      <ResizablePanelGroup
        storage={panelGroupStorage}
        autoSaveId='view'
        direction='horizontal'
      >
        <ResizablePanel
          autoSave='sideBar'
          minSize={10}
          defaultSize={20}
          maxSize={25}
        >
          <SideBar />
        </ResizablePanel>
        <ResizableHandle className='border-none' />
        <ResizablePanel autoSave='content' defaultSize={90}>
          <div className='flex flex-col gap-4 w-full h-full overflow-y-scroll lg:p-6 p-3 pb-6'>
            <TopBar />
            <Outlet />
          </div>
        </ResizablePanel>
      </ResizablePanelGroup>
      {import.meta.env.DEV && (
        <span className='absolute right-2 bottom-2 text-xs select-none text-muted-foreground/80'>
          Development Build
        </span>
      )}
    </div>
  ) : (
    <ErrorPage
      code={401}
      text='Unauthorized.'
      buttonHref='/login'
      buttonText='Login'
    />
  )
}

export default Layout
