import { Button } from '@/components/ui/button'
import {
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel
} from '@/components/ui/context-menu'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import AddressCell from '@/components/ui/Table/AddressCell'
import Table, { ICustomizableTableProps } from '@/components/ui/Table/Table'
import { formatAddress } from '@/lib/utils'
import { IAddress } from '@/types/address.type'
import { IVehicle } from '@/types/vehicles.type'
import { FC } from 'react'
import { TbDots } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import VehicleDeleteDialog from './VehicleDeleteDialog'

const VehiclesTable: FC<ICustomizableTableProps<IVehicle>> = (
  props: ICustomizableTableProps<IVehicle>
) => {
  const navigate = useNavigate()

  return (
    <Table
      {...props}
      initialState={{
        sorting: [
          {
            id: 'name',
            desc: false
          }
        ]
      }}
      contextMenu={(vehicle: IVehicle) => (
        <ContextMenuContent>
          <ContextMenuLabel>Actions</ContextMenuLabel>
          <ContextMenuItem onClick={() => navigate(`/vehicles/${vehicle.id}`)}>
            Edit
          </ContextMenuItem>
          <VehicleDeleteDialog vehicle={vehicle}>
            <ContextMenuItem>Delete</ContextMenuItem>
          </VehicleDeleteDialog>
        </ContextMenuContent>
      )}
      columns={[
        {
          id: 'name',
          header: 'Name',
          accessorFn: data => data.name,
          enableSorting: false,
          size: 400,
          minSize: 300,
          maxSize: 500,
          cell: props => <p>{props.getValue()}</p>
        },
        {
          id: 'make',
          header: 'Make',
          accessorFn: data => data,
          enableSorting: false,
          size: 400,
          minSize: 300,
          maxSize: 500,
          filterFn: (row, columnId, value) => {
            if (!value) return true

            const includes = (toCompare: string) =>
              toCompare?.toLowerCase()?.includes(value?.toLowerCase())

            return includes(row.original.make) || includes(row.original.model)
          },
          cell: props => (
            <p>
              {props.getValue().make} {props.getValue().model} (
              {props.getValue().year})
            </p>
          )
        },
        {
          id: 'type',
          header: 'Type',
          accessorFn: data => data.vehicleType,
          enableSorting: false,
          size: 200,
          minSize: 250,
          maxSize: 150,
          cell: props => <p>{String(props.getValue())}</p>
        },
        {
          accessorKey: 'garagingSite',
          header: 'Garaging Site',
          enableSorting: false,
          size: 700,
          minSize: 600,
          maxSize: 800,
          accessorFn: data => formatAddress(data.garagingSite as IAddress),
          cell: props => <AddressCell {...props} />
        },
        {
          id: 'actions',
          cell: props => (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant='ghost' className='h-8 w-8 p-0'>
                  <TbDots className='h-4 w-4' />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                onClick={event => event.stopPropagation()}
                align='end'
              >
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuItem
                  onClick={() => navigate(`/vehicles/${props.row.original.id}`)}
                >
                  Edit
                </DropdownMenuItem>
                <VehicleDeleteDialog vehicle={props.row.original}>
                  <DropdownMenuItem>Delete</DropdownMenuItem>
                </VehicleDeleteDialog>
              </DropdownMenuContent>
            </DropdownMenu>
          )
        }
      ]}
    />
  )
}

export default VehiclesTable
