import { toast } from '@/components/ui/use-toast'
import { setLogin, setLogout } from '@/reducers/authSlice'
import { store } from '@/store'
import { IAPIError } from '@/types/common.type'
import axios, { InternalAxiosRequestConfig } from 'axios'
import { isDate } from 'date-fns'
import { fromZonedTime, toZonedTime } from 'date-fns-tz'
import { jwtDecode } from 'jwt-decode'
import { cloneDeep } from 'lodash'
import authService from './auth.service'

const isoDateFormat =
  /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/

const isIsoDateString = (value: any): boolean => {
  return value && typeof value === 'string' && isoDateFormat.test(value)
}

export const handleResponseDates = (body: any) => {
  if (!body || typeof body !== 'object') return body

  Object.keys(body).forEach(key => {
    if (isIsoDateString(body[key])) {
      body[key] = toZonedTime(body[key], 'Etc/GMT+0')
      return body
    }

    if (typeof body[key] === 'object') handleResponseDates(body[key])
  })
}

export const handleRequestDates = (body: any) => {
  if (!body || typeof body !== 'object') return body

  Object.keys(body).forEach(key => {
    if (isDate(body[key]) || isIsoDateString(body[key])) {
      body[key] = fromZonedTime(body[key], 'Etc/GMT+0')
      return body
    }

    if (typeof body[key] === 'object') handleRequestDates(body[key])
  })
}

export const api = axios.create({
  baseURL: import.meta.env.VITE_APP_API_RESOURCE,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 5000
})

let refreshing: Promise<any> | null = null

const handleRequestTokens = async (
  request: InternalAxiosRequestConfig<any>
) => {
  const tokens = store.getState().authState.auth

  if (!tokens) return

  request.headers.set('Authorization', `Bearer ${tokens?.accessToken}`)

  const currentTime = Math.floor(new Date().getTime() / 1000)
  const decoded = jwtDecode(tokens.accessToken)

  if (decoded.exp && currentTime >= decoded.exp) {
    refreshing = authService
      .refresh(tokens.refreshToken)
      .then(response => {
        store.dispatch(setLogin(response.data))

        request.headers.set(
          'Authorization',
          `Bearer ${response.data.accessToken}`
        )
      })
      .catch(error => {
        if (error.statusCode === 403) {
          store.dispatch(setLogout())
          toast({
            title: 'Session expired',
            description:
              'Your session has expired due to inactivity. Please log in again to continue.',
            variant: 'destructive'
          })
        }
      })
      .finally(() => {
        refreshing = null
      })

    await refreshing
  }
}

api.interceptors.request.use(async request => {
  /*
    Don't do anything if request uses custom authorization 
    For example - token refreshing requires different Authorization
  */
  if (request.headers.Authorization) return request

  if (refreshing) await refreshing

  await handleRequestTokens(request)

  if (
    request.data &&
    request.headers['Content-Type'] !== 'multipart/form-data'
  ) {
    const requestData = cloneDeep(request.data)
    handleRequestDates(requestData)
    request.data = requestData
  }
  if (request.params) {
    const requestParams = cloneDeep(request.params)
    handleRequestDates(requestParams)
    request.params = requestParams
  }

  return request
})

api.interceptors.response.use(
  response => {
    handleResponseDates(response.data)
    return response
  },
  async error => {
    if (error.code === 'ERR_NETWORK') {
      if (navigator.onLine)
        toast({
          title: 'No connection!',
          description:
            'We apologize for the inconvenience, the API is currently down. Please try again later.',
          variant: 'destructive'
        })
      else
        toast({
          title: 'No connection!',
          description:
            'Unable to connect to the API. Please check your network connection and try again.',
          variant: 'destructive'
        })

      return
    }

    if (!!error.response.data) {
      return Promise.reject(error.response.data as IAPIError)
    }

    Promise.reject({
      error: 'UnhandledException',
      message: 'Unable to handle error! Check developer logs in console.',
      statusCode: -1
    })

    console.error(error)
  }
)
