import { useToast } from '@/components/ui/use-toast'
import vehiclesService from '@/services/vehicles.service'
import { IAPIError } from '@/types/common.type'
import { IVehicle } from '@/types/vehicles.type'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useDeleteVehicle = () => {
  const queryClient = useQueryClient()

  const { toast } = useToast()

  return useMutation({
    mutationFn: async (vehicle: IVehicle) => {
      if (!vehicle?.id) throw new Error(`No vehicle selected.`)

      await vehiclesService.delete(vehicle.id)

      return vehicle
    },
    onSuccess: async data => {
      toast({
        title: 'Deleted!',
        description: `Vehicle ${data?.make} ${data.model} (#${data.id}) has been removed successfully.`
      })

      queryClient.invalidateQueries({ queryKey: ['vehicles'] })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}
