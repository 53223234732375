import { Button } from '@/components/ui/button'
import {
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel
} from '@/components/ui/context-menu'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import DriverPreview from '@/components/ui/previews/DriverPreview'
import VehiclePreview from '@/components/ui/previews/VehiclePreview'
import Table, { ICustomizableTableProps } from '@/components/ui/Table/Table'
import { ITimeRange } from '@/types/common.type'
import { IShift } from '@/types/shifts.type'
import { format } from 'date-fns'
import { FC } from 'react'
import { TbDots } from 'react-icons/tb'
import { Link, useNavigate } from 'react-router-dom'

const DispatchPageShiftsTable: FC<ICustomizableTableProps<IShift>> = (
  props: ICustomizableTableProps<IShift>
) => {
  const navigate = useNavigate()

  return (
    <Table
      {...props}
      disablePagination
      notFoundText={
        <div>
          <p>No shifts were found for selected day.</p>
          <Link to='/shifts/create'>Click to create.</Link>
        </div>
      }
      contextMenu={(shift: IShift) => (
        <ContextMenuContent>
          <ContextMenuLabel>Actions</ContextMenuLabel>
          <ContextMenuItem
            onClick={() => navigate(`/drivers/${shift.driverId}`)}
          >
            Edit Driver
          </ContextMenuItem>
          <ContextMenuItem onClick={() => navigate(`/shifts/${shift.id}`)}>
            Edit Shift
          </ContextMenuItem>
          <ContextMenuItem
            onClick={() => navigate(`/vehicles/${shift.vehicleId}`)}
          >
            Edit Vehicle
          </ContextMenuItem>
        </ContextMenuContent>
      )}
      columns={[
        {
          accessorKey: 'driver',
          header: 'Driver',
          accessorFn: data => data.driver,
          cell: props => <DriverPreview value={props.getValue()} />
        },
        {
          accessorKey: 'vehicle',
          header: 'Vehicle',
          accessorFn: data => data.vehicle,
          cell: props => (
            <VehiclePreview value={props.getValue()} className='text-xs' />
          )
        },
        {
          id: 'workingTime',
          accessorKey: 'workingTime',
          header: 'Working Time',
          cell: props => (
            <span className='whitespace-nowrap'>
              {format(props.getValue().start, 'HH:mm')} -{' '}
              {format(props.getValue().end, 'HH:mm')}
            </span>
          )
        },
        {
          id: 'breaks',
          header: 'Breaks',
          accessorKey: 'breaks',
          cell: props => {
            return props.getValue().map((break_: ITimeRange) => (
              <p className='whitespace-nowrap'>
                {format(break_.start, 'HH:mm')} - {format(break_.end, 'HH:mm')}
              </p>
            ))
          }
        },
        {
          id: 'actions',
          size: 20,
          minSize: 20,
          maxSize: 20,
          cell: props => (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant='ghost' className='h-8 w-8 p-0'>
                  <TbDots className='h-4 w-4' />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                onClick={event => event.stopPropagation()}
                align='end'
              >
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuItem
                  onClick={() =>
                    navigate(`/drivers/${props.row.original.driverId}`)
                  }
                >
                  Edit Driver
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => navigate(`/shifts/${props.row.original.id}`)}
                >
                  Edit Shift
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() =>
                    navigate(`/vehicles/${props.row.original.vehicleId}`)
                  }
                >
                  Edit Vehicle
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )
        }
      ]}
    />
  )
}
export default DispatchPageShiftsTable
