import { useToast } from '@/components/ui/use-toast'
import { setLogin, setLogout } from '@/reducers/authSlice'
import { setUser } from '@/reducers/userSlice'
import authService from '@/services/auth.service'
import usersService from '@/services/users.service'
import { IAPIError } from '@/types/common.type'
import { UserRole } from '@/types/users.type'
import { useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export const useLogin = () => {
  const { toast } = useToast()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return useMutation({
    mutationFn: async ({
      username,
      password
    }: {
      username: string
      password: string
    }) => await authService.login(username, password),
    onSuccess: async ({ data }) => {
      dispatch(setLogin(data))

      const me = (await usersService.getMe()).data

      if (me.role === UserRole.Driver) {
        toast({
          title: 'Error!',
          description: `You don't have enough permissions to log in here. Use mobile application to log in.`,
          variant: 'destructive'
        })
        dispatch(setLogout())

        return
      }

      dispatch(setUser(me))

      navigate('/')

      toast({
        title: 'Logged in!',
        description: `Welcome back to ManageNEMT, ${me.username}.`
      })
    },
    onError: (error: IAPIError) => {
      if (error.statusCode === 403)
        return toast({
          title: 'Invalid credentials!',
          description:
            'You entered wrong username or password. Please, checked entered credentials and try again.',
          variant: 'destructive'
        })

      if (error.statusCode === 405)
        return toast({
          title: 'Registration is not completed!',
          description:
            'Check your email for the invitation email. Try again after completing the registration.',
          variant: 'destructive'
        })

      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}
