import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/ui/command'
import { Label } from '@/components/ui/label'
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover'
import { useVehicle } from '@/hooks/queries/useVehicle'
import { useVehiclesSearch } from '@/hooks/queries/useVehiclesSearch'
import { cn } from '@/lib/utils'
import { IVehicle } from '@/types/vehicles.type'
import { useQueryClient } from '@tanstack/react-query'
import { FC, useState } from 'react'
import { TbCheck, TbSearch } from 'react-icons/tb'
import { Link } from 'react-router-dom'
import VehiclePreview from '../previews/VehiclePreview'
import Spinner from '../Spinner'

interface IVehicleInputProps {
  value: number
  notWorkingDay?: Date
  id?: string
  title?: string
  required?: boolean
  fieldClassName?: string
  popoverClassName?: string
  onChange?: (value: IVehicle) => any
}

const VehicleInput: FC<IVehicleInputProps> = (props: IVehicleInputProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [input, setInput] = useState<string>('')

  const data = useVehicle(props.value as number)

  const search = useVehiclesSearch(input, props.notWorkingDay)

  const queryClient = useQueryClient()

  return (
    <div className={cn('flex flex-col gap-2')}>
      {props.title && (
        <Label htmlFor={props.id}>
          {props.title}
          {props.required && ' *'}
        </Label>
      )}
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant='outline'
            role='combobox'
            className={cn('h-8 w-64 justify-between', props.fieldClassName)}
            aria-expanded={open}
          >
            <span className='overflow-hidden whitespace-nowrap text-ellipsis'>
              {data.data
                ? `${data.data.name} - ${data.data.make} ${data.data.model} (${data.data.year})`
                : `Search for ${props.notWorkingDay ? 'available ' : ''}vehicles...`}
            </span>
            <TbSearch />
          </Button>
        </PopoverTrigger>
        <PopoverContent className={cn('w-64 p-0', props.popoverClassName)}>
          <Command shouldFilter={false}>
            <CommandInput
              placeholder={`Search for ${props.notWorkingDay ? 'available ' : ''}vehicles...`}
              value={input}
              onValueChange={setInput}
            />
            {input && !search.isLoading && (
              <CommandEmpty>
                <Link to='/vehicles/create'>
                  No results found.
                  <br />
                  Click to create a new Vehicle.
                </Link>
              </CommandEmpty>
            )}
            <CommandList>
              <CommandGroup>
                {search.isLoading && (
                  <div className='flex justify-center my-2'>
                    <Spinner />
                  </div>
                )}
                {search.data?.map(vehicle => (
                  <CommandItem
                    key={vehicle.id}
                    value={`${vehicle.name} ${vehicle.model} ${vehicle.name} ${vehicle.year} ${vehicle.licensePlateNumber}`}
                    onSelect={() => {
                      queryClient.setQueryData(['vehicle', vehicle.id], vehicle)
                      props.onChange?.(vehicle)
                      setOpen(false)
                    }}
                  >
                    <TbCheck
                      className={`w-4 h-4 mr-2 ${props.value === vehicle.id ? 'opacity-100' : 'opacity-0'}`}
                    />
                    <VehiclePreview value={vehicle} />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default VehicleInput
