import { useToast } from '@/components/ui/use-toast'
import brokersService from '@/services/brokers.service'
import { IBroker } from '@/types/brokers.type'
import { IAPIError } from '@/types/common.type'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useDeleteBroker = () => {
  const queryClient = useQueryClient()

  const { toast } = useToast()

  return useMutation({
    mutationFn: async (broker: IBroker) => {
      if (!broker) throw new Error(`No broker selected.`)

      await brokersService.delete(broker.id)
      return broker
    },
    onSuccess: broker => {
      toast({
        title: 'Deleted!',
        description: `Broker ${broker?.name} (#${broker?.id}) was deleted successfully.`
      })

      queryClient.invalidateQueries({ queryKey: ['brokers'] })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}
