import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import { Progress } from '@/components/ui/progress'
import { Skeleton } from '@/components/ui/skeleton'
import { useTrips } from '@/hooks/queries/useTrips'
import { ITrip, ScheduleStatus } from '@/types/trips.type'
import { endOfToday, startOfToday } from 'date-fns'
import { FC, HTMLAttributes, useMemo } from 'react'

const countTripsByStatuses = (trips: ITrip[], statuses: ScheduleStatus[]) =>
  trips.filter(trip =>
    trip.schedules.some(schedule => statuses.includes(schedule.status))
  ).length || 0

const CompletedToday: FC<HTMLAttributes<HTMLDivElement>> = (
  props: HTMLAttributes<HTMLDivElement>
) => {
  const trips = useTrips(startOfToday(), endOfToday())

  const completedToday = useMemo(
    () =>
      trips.data
        ? countTripsByStatuses(trips.data, [
            ScheduleStatus.Completed,
            ScheduleStatus.NoShow,
            ScheduleStatus.Canceled
          ])
        : 0,
    [trips.data]
  )

  const completionPercentage = useMemo(() => {
    if (!trips.data || trips.data.length === 0) return 0
    return (completedToday / trips.data.length) * 100
  }, [completedToday, trips.data])

  return (
    <Card {...props}>
      <CardHeader>
        <CardTitle>Completed Today</CardTitle>
        <CardDescription>
          {trips.data ? (
            <span>
              There are {completedToday} of {trips.data?.length} completed trips
              today.
            </span>
          ) : (
            <>
              <Skeleton className='w-full h-6 mt-4' />
              <Skeleton className='w-full rounded-full h-4 mt-4' />
            </>
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        {trips.data && <Progress value={completionPercentage} />}
      </CardContent>
    </Card>
  )
}
export default CompletedToday
