import { useToast } from '@/components/ui/use-toast'
import shiftsService from '@/services/shifts.service'
import { IAPIError } from '@/types/common.type'
import { IShift } from '@/types/shifts.type'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useDeleteShift = () => {
  const queryClient = useQueryClient()

  const { toast } = useToast()

  return useMutation({
    mutationFn: async (shift: IShift) => {
      if (!shift) throw new Error('No shift selected.')

      await shiftsService.delete(shift.id)

      return shift
    },
    onSuccess: async data => {
      toast({
        title: 'Deleted!',
        description: `Shift #${data.id} was deleted successfully.`
      })

      queryClient.invalidateQueries({ queryKey: ['shifts'] })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}
