import { yupTransformer } from '@/lib/yup'
import { ISchemaUser, userSchema } from '@/types/users.type'
import { boolean, date, mixed, object, ObjectSchema, string } from 'yup'
import { addressSchema, IAddress } from './address.type'
import { IUser } from './users.type'

export enum SpecialAssistance {
  Blind = 'Blind',
  Walker = 'Walker',
  Cane = 'Cane'
}

export interface IDriver {
  id: number
  socialSecurityNumber?: string
  dateOfBirth?: Date
  licenseNumber?: string
  licenseType?: string
  licenseState?: string
  licenseExpires?: Date
  canServeChildren?: boolean
  address?: IAddress
  notes?: string
  specialAssistance?: SpecialAssistance
  userId: number
  user: IUser
}

export interface ISchemaDriver extends Omit<IDriver, 'id' | 'userId' | 'user'> {
  user: ISchemaUser
}

export const driverSchema: ObjectSchema<ISchemaDriver> = object({
  socialSecurityNumber: string()
    .matches(/^\d{3}-\d{2}-\d{4}$/, 'SSN format: AAA-GG-SSSS')
    .optional(),
  dateOfBirth: date().max(new Date(), 'Must be earlier than today.').optional(),
  licenseNumber: string().optional(),
  licenseType: string().optional(),
  licenseState: string().optional(),
  licenseExpires: date().optional(),
  canServeChildren: boolean().optional(),
  address: addressSchema.optional(),
  notes: string().optional(),
  specialAssistance: mixed<SpecialAssistance>().optional(),
  user: userSchema.required('User is required.')
}).transform(yupTransformer)
