import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import persistStore from 'redux-persist/es/persistStore'
import storage from 'redux-persist/lib/storage'
import authSlice from './reducers/authSlice'
import settingsSlice from './reducers/settingsSlice'
import userSlice from './reducers/userSlice'

const persistConfig = {
  key: 'storage',
  storage
}

const rootReducer = combineReducers({
  userState: userSlice,
  authState: authSlice,
  settingsState: settingsSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: import.meta.env.DEV,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

export const persistor = persistStore(store)

store.subscribe(() => {})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
