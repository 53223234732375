import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import { useDeleteShift } from '@/hooks/mutations/useDeleteShift'
import { IShift } from '@/types/shifts.type'
import { FC, ReactNode } from 'react'

interface IShiftDeleteDialogProps {
  shift: IShift
  children: ReactNode
}

const ShiftDeleteDialog: FC<IShiftDeleteDialogProps> = (
  props: IShiftDeleteDialogProps
) => {
  const { mutate: deleteShift } = useDeleteShift()

  return (
    <AlertDialog>
      <AlertDialogTrigger onSelect={e => e.preventDefault()} asChild>
        {props.children}
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete this
            shift.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={() => deleteShift(props.shift)}>
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
export default ShiftDeleteDialog
