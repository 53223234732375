import { useAppSelector } from '@/hooks/useAppSelector'
import { FC, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import LoginForm from './LoginForm'
import PasswordRecoveryForm from './PasswordRecoveryForm'

const LoginPage: FC = () => {
  const navigate = useNavigate()

  const isAuthorized = useAppSelector(state => state.authState.authorized)

  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (isAuthorized) navigate('/')
  }, [])

  return (
    <>
      <div
        style={{
          backgroundImage: `url(wallpaper.svg)`
        }}
        className='fixed h-screen w-screen dark:invert -z-10 bg-no-repeat bg-fill bg-cover opacity-90'
      />
      {searchParams.get('forgot-password') === '1' ? (
        <PasswordRecoveryForm />
      ) : (
        <LoginForm />
      )}
    </>
  )
}

export default LoginPage
