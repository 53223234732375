import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from '@/components/ui/collapsible'
import { FC, ReactNode, useState } from 'react'
import { IconType } from 'react-icons'
import { TbChevronDown, TbChevronUp } from 'react-icons/tb'

interface ILeftBarCategoryProps {
  icon: IconType
  title: string
  children?: ReactNode
}

const LeftBarCategory: FC<ILeftBarCategoryProps> = (
  props: ILeftBarCategoryProps
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className='space-y-2 p-2'
    >
      <CollapsibleTrigger className='w-full mr-4'>
        <div className='flex flex-row place-items-center sm:justify-start justify-center'>
          <div className='flex gap-3 place-items-center mr-2'>
            <props.icon className='h-6 w-6' />
            <h4 className='hidden sm:block text-sm font-medium'>
              {props.title}
            </h4>
          </div>
          {isOpen ? (
            <TbChevronUp className='h-4 w-4' />
          ) : (
            <TbChevronDown className='h-4 w-4' />
          )}
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className='flex flex-col gap-2'>
        {props.children}
      </CollapsibleContent>
    </Collapsible>
  )
}
export default LeftBarCategory
