import ScheduleStatusBadge from '@/components/tables/TripsTable/ScheduleStatusBadge'
import BigCalendar from '@/components/ui/big-calendar/big-calendar'
import TripPreview from '@/components/ui/previews/TripPreview'
import { Skeleton } from '@/components/ui/skeleton'
import { format, getDay, parse, startOfWeek } from 'date-fns'
import { enUS } from 'date-fns/locale'
import { FC, useContext } from 'react'
import { dateFnsLocalizer, Views } from 'react-big-calendar'
import DispatchPageContext from './DispatchPageContext'
import DispatchPageShiftsTable from './tables/ShiftsTable'

const locales = {
  'en-US': enUS
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
})

const CalendarView: FC = () => {
  const context = useContext(DispatchPageContext)

  const calendarEvents = context.trips
    ?.filter(trip => trip.driverId === context.selectedDriver?.id)
    .map((trip, index) => {
      const start = Math.min(
        ...trip.schedules.map(schedule =>
          new Date(
            schedule.scheduledPickupTime ?? schedule.pickupTime
          ).getTime()
        )
      )
      const end = Math.max(
        ...trip.schedules.map(schedule =>
          new Date(
            schedule.scheduledDropoffTime ?? schedule.dropoffTime
          ).getTime()
        )
      )

      return {
        id: index,
        start: new Date(start),
        end: new Date(end),
        resource: trip,
        resourceId: trip.id
      }
    })

  return (
    <div className='flex flex-row gap-4 min-h-full w-full pb-12'>
      {context.shifts ? (
        <DispatchPageShiftsTable
          data={context.shifts}
          className='w-[30%] h-full'
          onSelectionChange={(value, row) =>
            context.setSelectedDriver(value?.driver)
          }
        />
      ) : (
        <div className='flex flex-col gap-4 min-w-[30%] h-full border rounded-md p-2'>
          <Skeleton className='w-full h-16' />
          <Skeleton className='w-full h-16' />
          <Skeleton className='w-full h-16' />
          <Skeleton className='w-full h-16' />
        </div>
      )}
      {context.trips ? (
        <div className='w-full'>
          {!context.selectedDriver ? (
            <div className='w-full h-full flex justify-center place-items-center border rounded-md text-muted-foreground text-sm'>
              <p>Select a driver to view their trips.</p>
            </div>
          ) : (
            <BigCalendar
              className='w-full h-full [&>*:first-child]:border-none'
              events={calendarEvents}
              defaultDate={context.date}
              defaultView={Views.DAY}
              localizer={localizer}
              onShowMore={event => console.log(event)}
              selectable={false}
              toolbar={false}
              timeslots={2}
              components={{
                eventWrapper: eventWrapperProps => {
                  return (
                    <div>
                      <TripPreview
                        trip={eventWrapperProps.event.resource}
                        /* @ts-ignore: Bad library type declaration. */
                        children={eventWrapperProps.children}
                      />
                    </div>
                  )
                },
                event: eventProps => {
                  return (
                    <div className='flex flex-row gap-2'>
                      {eventProps.event.resource.schedules.map(schedule => (
                        <span>
                          {schedule.rider.firstName} {schedule.rider.lastName}{' '}
                          <ScheduleStatusBadge
                            className='h-4'
                            value={schedule.status}
                          />
                        </span>
                      ))}
                    </div>
                  )
                }
              }}
            />
          )}
        </div>
      ) : (
        <div className='flex flex-col gap-4 w-full h-full border rounded-md p-2'>
          <Skeleton className='w-full h-20' />
          <Skeleton className='w-full h-20' />
          <Skeleton className='w-full h-20' />
          <Skeleton className='w-full h-20' />
          <Skeleton className='w-full h-20' />
          <Skeleton className='w-full h-20' />
          <Skeleton className='w-full h-20' />
        </div>
      )}
    </div>
  )
}
export default CalendarView

