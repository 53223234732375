import { Button } from '@/components/ui/button'
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator
} from '@/components/ui/command'
import { useDriversSearch } from '@/hooks/queries/useDriversSearch'
import { useRidersSearch } from '@/hooks/queries/useRidersSearch'
import { useVehiclesSearch } from '@/hooks/queries/useVehiclesSearch'
import { ISearchable, routes } from '@/routes'
import { FC, useEffect, useMemo, useState } from 'react'
import { TbAnchor, TbSearch, TbTruck, TbUser } from 'react-icons/tb'
import { RouteObject, useNavigate } from 'react-router-dom'

const routeFinder = (routes: RouteObject[]): ISearchable[] => {
  const recursion = (routes: RouteObject[]): RouteObject[] =>
    routes
      .map((route: RouteObject) => {
        if (route.children) return [route, ...recursion(route.children)].flat()

        return [route]
      })
      .flat()

  return recursion(routes).flatMap(route => {
    if (route.handle?.search) return route.handle.search

    return []
  })
}

const SearchBar: FC = () => {
  const navigate = useNavigate()

  const [input, setInput] = useState<string>('')
  const [open, setOpen] = useState(false)

  const riders = useRidersSearch(input, 400)
  const drivers = useDriversSearch(input, undefined, 400)
  const vehicles = useVehiclesSearch(input, undefined, 400)

  const searchData = useMemo(() => routeFinder(routes), [])
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === 'k' && (event.metaKey || event.ctrlKey)) {
        event.preventDefault()
        setOpen(open => !open)
      }
    }

    document.addEventListener('keydown', listener)
    return () => document.removeEventListener('keydown', listener)
  }, [open])

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant='outline'
        className='justify-between text-muted-foreground w-full min-h-8 overflow-hidden'
      >
        <div className='flex flex-row place-items-center w-full justify-center sm:justify-start gap-2'>
          <TbSearch className='h-4 w-4' />
          <span className='hidden sm:block'>Search...</span>
        </div>
        <kbd className='sm:flex place-items-center rounded-sm border bg-muted px-1.5 h-5 text-[10px] hidden'>
          <span className='text-xs mr-0.5'>
            {navigator.userAgent.includes('Mac') ? '⌘' : '⌃'}
          </span>
          K
        </kbd>
      </Button>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput
          placeholder='Search...'
          value={input}
          onValueChange={setInput}
        />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          <CommandGroup heading='Pages'>
            {searchData.map(search => (
              <CommandItem
                onSelect={() => {
                  navigate(search.path)
                  setOpen(false)
                }}
                key={search.name}
                value={search.name}
                className='flex flex-row gap-2'
              >
                <search.icon />
                {search.name}
              </CommandItem>
            ))}
          </CommandGroup>
          <CommandSeparator />
          <CommandGroup heading='Riders'>
            {riders.data?.map(rider => (
              <CommandItem
                onSelect={() => {
                  navigate(`/riders/${rider.id}`)
                  setOpen(false)
                }}
                key={`rider-${rider.id}`}
                value={`${rider.firstName} ${rider.lastName}`}
                className='flex flex-row gap-2'
              >
                <TbAnchor />
                {rider.firstName} {rider.lastName}
              </CommandItem>
            ))}
          </CommandGroup>
          <CommandSeparator />
          <CommandGroup heading='Drivers'>
            {drivers.data?.map(driver => (
              <CommandItem
                onSelect={() => {
                  navigate(`/drivers/${driver.id}`)
                  setOpen(false)
                }}
                key={`driver-${driver.id}`}
                value={`${driver.user.firstName} ${driver.user.lastName}`}
                className='flex flex-row gap-2'
              >
                <TbUser />
                {driver.user.firstName} {driver.user.lastName} (
                {driver.user.username})
              </CommandItem>
            ))}
          </CommandGroup>
          <CommandSeparator />
          <CommandGroup heading='Vehicles'>
            {vehicles.data?.map(vehicle => (
              <CommandItem
                onSelect={() => {
                  navigate(`/vehicles/${vehicle.id}`)
                  setOpen(false)
                }}
                key={`vehicle-${vehicle.id}`}
                value={`${vehicle.make} ${vehicle.model} ${vehicle.name} ${vehicle.year} ${vehicle.licensePlateNumber}`}
                className='flex flex-row gap-2'
              >
                <TbTruck />
                {vehicle.name} - {vehicle.make} {vehicle.model} ({vehicle.year})
              </CommandItem>
            ))}
          </CommandGroup>
        </CommandList>
      </CommandDialog>
    </>
  )
}
export default SearchBar
