import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import DateInput from '@/components/ui/inputs/dateInput/DateInput'
import EnumInput from '@/components/ui/inputs/EnumInput'
import Spinner from '@/components/ui/Spinner'
import { useRider } from '@/hooks/queries/useRider'
import { Gender } from '@/types/common.type'
import { IRider, ISchemaRider, riderSchema } from '@/types/riders.type'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { TbPencil } from 'react-icons/tb'
import { useParams } from 'react-router-dom'
import DemandRides from './DemandRides'
import { useCreateOrUpdateRider } from './hooks/useCreateOrUpdateRider'

const RiderForm: FC = () => {
  const riderId = Number(useParams().riderId)

  const rider = useRider(riderId)

  const form = useForm<ISchemaRider>({
    resolver: yupResolver(riderSchema)
  })

  useEffect(() => form.reset(rider.data), [rider.data])

  const { mutate: submit } = useCreateOrUpdateRider()

  return (
    <Form {...form}>
      {rider.isLoading ? (
        <Spinner />
      ) : (
        <form
          className='flex flex-col gap-4'
          onSubmit={form.handleSubmit(data => submit(data as IRider))}
        >
          <div className='flex flex-row gap-4'>
            <FormField
              control={form.control}
              name='firstName'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>First Name *</FormLabel>
                  <FormControl>
                    <Input className='w-64 h-8' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='middleName'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Middle Name</FormLabel>
                  <FormControl>
                    <Input className='w-64 h-8' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='lastName'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last Name *</FormLabel>
                  <FormControl>
                    <Input className='w-64 h-8' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='weight'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Weight</FormLabel>
                  <FormControl>
                    <Input className='w-64 h-8' {...field} type='number' />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className='flex flex-row gap-4'>
            <FormField
              control={form.control}
              name='email'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      placeholder='inbox@example.com'
                      className='w-64 h-8'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='phone'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Phone Number</FormLabel>
                  <FormControl>
                    <Input
                      placeholder='+1 (234) 567-8910'
                      className='w-64 h-8'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='socialSecurityNumber'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Social Security Number</FormLabel>
                  <FormControl>
                    <Input
                      placeholder='123-45-6789'
                      className='w-64 h-8'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='medicaidNumber'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Medicaid Number</FormLabel>
                  <FormControl>
                    <Input className='w-64 h-8' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className='flex flex-row gap-4'>
            <FormField
              control={form.control}
              name='dateOfBirth'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Date of Birth</FormLabel>
                  <FormControl>
                    <DateInput className='w-64' {...field} type='date' />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='gender'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Gender</FormLabel>
                  <FormControl>
                    <EnumInput
                      fieldClassName='w-64'
                      popoverClassName='w-64'
                      enum={Gender}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='language'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Language</FormLabel>
                  <FormControl>
                    <Input className='w-64 h-8' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='notes'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Notes</FormLabel>
                  <FormControl>
                    <Input className='w-64 h-8' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className='flex flex-row gap-4'>
            <Button className='w-64 h-8' type='submit'>
              <TbPencil className='mr-1' />
              Save changes
            </Button>
            {!isNaN(riderId) && <DemandRides riderId={riderId} />}
          </div>
        </form>
      )}
    </Form>
  )
}

export default RiderForm
