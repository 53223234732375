import TripsTable from '@/components/tables/TripsTable/TripsTable'
import { Button } from '@/components/ui/button'
import DateInput from '@/components/ui/inputs/dateInput/DateInput'
import EnumInput from '@/components/ui/inputs/EnumInput'
import RiderInput from '@/components/ui/inputs/RiderInput'
import TextInput from '@/components/ui/inputs/TextInput'
import Spinner from '@/components/ui/Spinner'
import { useTrips } from '@/hooks/queries/useTrips'
import { ITimeRange } from '@/types/common.type'
import { IRider } from '@/types/riders.type'
import { ScheduleStatus } from '@/types/trips.type'
import { endOfDay, endOfToday, startOfDay, startOfToday } from 'date-fns'
import { FC, useState } from 'react'
import { TbPlus } from 'react-icons/tb'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ImportDialog from './import/ImportDialog'

const TripsPage: FC = () => {
  const [statusFilter, setStatusFilter] = useState<ScheduleStatus>()
  const [fromFilter, setFromFilter] = useState<string>('')
  const [toFilter, setToFilter] = useState<string>('')
  const [riderFilter, setRiderFilter] = useState<IRider>()
  const [timeFilter, setTimeFilter] = useState<ITimeRange>({
    start: startOfToday(),
    end: endOfToday()
  })

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()
  const trips = useTrips(timeFilter.start, timeFilter.end)

  return (
    <div className='flex flex-col gap-4 w-full h-full'>
      <div className='flex flex-row w-full justify-between'>
        <div className='flex flex-row gap-2 text-sm'>
          <EnumInput
            title='Status'
            id='status'
            enum={ScheduleStatus}
            fieldClassName='w-36'
            popoverClassName='w-36'
            value={statusFilter as ScheduleStatus}
            onChange={value => {
              setStatusFilter(value)
            }}
          />
          <DateInput
            value={timeFilter.start}
            type='date'
            id='statringFrom'
            title='Starting from'
            className='w-32'
            onChange={async value => {
              if (value > timeFilter.end)
                return setTimeFilter({
                  start: startOfDay(value),
                  end: endOfDay(value)
                })

              setTimeFilter({ ...timeFilter, start: startOfDay(value) })
            }}
          />
          <DateInput
            value={timeFilter.end}
            type='date'
            id='endingAt'
            title='Ending at'
            className='w-32'
            onChange={async value => {
              if (value < timeFilter.start)
                return setTimeFilter({
                  start: startOfDay(value),
                  end: endOfDay(value)
                })

              setTimeFilter({ ...timeFilter, end: endOfDay(value) })
            }}
          />
          <TextInput
            title='From'
            id='from'
            value={fromFilter}
            onChange={value => setFromFilter(value)}
            className='w-40 h-8'
          />
          <TextInput
            title='To'
            id='to'
            value={toFilter}
            onChange={value => setToFilter(value)}
            className='w-40 h-8'
          />
          <RiderInput
            title='Rider'
            id='rider'
            fieldClassName='w-48'
            popoverClassName='w-48'
            value={riderFilter?.id}
            onChange={value => setRiderFilter(value)}
          />
        </div>
        <div className='flex flex-row gap-2 right-0 h-full place-items-end'>
          <ImportDialog defaultOpen={searchParams.get('import') !== null} />
          <Button
            onClick={() => {
              navigate(`/trips/create`)
            }}
            className='h-8'
          >
            <TbPlus className='h-4 w-4 mr-2' />
            Create
          </Button>
        </div>
      </div>
      {!trips.isLoading && trips.data ? (
        <TripsTable
          className='w-full'
          columnFilters={[
            {
              id: 'expander',
              value: statusFilter
            },
            {
              id: 'from',
              value: fromFilter
            },
            {
              id: 'to',
              value: toFilter
            },
            {
              id: 'rider',
              value: riderFilter
            }
          ]}
          data={trips.data}
        />
      ) : (
        <Spinner />
      )}
    </div>
  )
}

export default TripsPage
