import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { PhoneInput } from '@/components/ui/phone-input'
import { adminSchema, IAdmin, ISchemaAdmin } from '@/types/admins.type'
import { yupResolver } from '@hookform/resolvers/yup'
import { DialogProps } from '@radix-ui/react-dialog'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { TbMail } from 'react-icons/tb'
import { useCreateAdmin } from './hooks/useCreateAdmin'

const AdminInviteDialog: FC<DialogProps> = (props: DialogProps) => {
  const form = useForm<ISchemaAdmin>({
    resolver: yupResolver(adminSchema)
  })

  const [open, setOpen] = useState<boolean>(props.defaultOpen || false)

  const { mutate: createAdmin } = useCreateAdmin()

  const submit = (data: ISchemaAdmin) => {
    createAdmin(data as IAdmin, {
      onSuccess: () => setOpen(false)
    })
  }

  return (
    <Dialog {...props} open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className='h-8'>
          <TbMail className='h-4 w-4 mr-2' />
          Invite
        </Button>
      </DialogTrigger>
      <DialogContent>
        <Form {...form}>
          <form
            className='flex flex-col gap-4'
            onSubmit={form.handleSubmit(submit)}
          >
            <DialogHeader>
              <DialogTitle>Invite new Admin</DialogTitle>
              <DialogDescription>
                Invite new admins by email to join your company.
              </DialogDescription>
            </DialogHeader>
            <div className='flex flex-row gap-2 w-full'>
              <FormField
                control={form.control}
                name='user.firstName'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>First Name *</FormLabel>
                    <FormControl>
                      <Input {...field} className='h-8' />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='user.middleName'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Middle Name</FormLabel>
                    <FormControl>
                      <Input {...field} className='h-8' />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='user.lastName'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Last Name *</FormLabel>
                    <FormControl>
                      <Input {...field} className='h-8' />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name='user.username'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Username *</FormLabel>
                  <FormControl>
                    <Input {...field} className='h-8' />
                  </FormControl>
                  <FormDescription>
                    Username is what admin will be logging in with.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='user.email'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email *</FormLabel>
                  <FormControl>
                    <Input {...field} className='h-8' />
                  </FormControl>
                  <FormDescription>
                    Email will be used to send an invitation link to the admin.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='user.phone'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Phone Number</FormLabel>
                  <FormControl>
                    <PhoneInput
                      {...field}
                      defaultCountry='US'
                      className='h-8'
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter className='relative'>
              <span className='absolute left-0 bottom-0 text-muted-foreground text-xs'>
                You can change these and other fields after invitation.
              </span>
              <Button type='submit'>
                <TbMail className='h-4 w-4 mr-2' />
                Invite
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
export default AdminInviteDialog

