import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import BrokerInput from '@/components/ui/inputs/BrokerInput'
import DateInput from '@/components/ui/inputs/dateInput/DateInput'
import EnumInput from '@/components/ui/inputs/EnumInput'
import LocationInput from '@/components/ui/inputs/LocationInput'
import RiderInput from '@/components/ui/inputs/RiderInput'
import Spinner from '@/components/ui/Spinner'
import { useSignature } from '@/hooks/queries/useSignature'
import { cn } from '@/lib/utils'
import { Mobility } from '@/types/common.type'
import { ISchedule, ISchemaTrip, ScheduleStatus } from '@/types/trips.type'
import { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { TbChevronRight, TbPlus, TbTrash } from 'react-icons/tb'
import MapPreview from './MapPreview'

interface IScheduleFormProps {
  hideTitle: boolean
  created: boolean
  onDelete: () => any
  index: number
  form: UseFormReturn<ISchemaTrip, any, undefined>
}

const ScheduleForm: FC<IScheduleFormProps> = (props: IScheduleFormProps) => {
  const signature = useSignature(
    props.form.getValues().schedules[props.index] as ISchedule
  )

  const handleAddGuest = () =>
    props.form.setValue(`schedules.${props.index}.guests`, [
      ...(props.form.getValues(`schedules.${props.index}.guests`) || []),
      Mobility.Ambulatory
    ])

  const handleDeleteGuest = (index: number) => {
    const filteredGuests = props.form
      .getValues(`schedules.${props.index}.guests`)
      ?.filter((_, i) => i !== index)

    props.form.setValue(`schedules.${props.index}.guests`, filteredGuests)
  }

  return (
    <Card className='relative'>
      {!props.hideTitle && (
        <CardHeader>
          <CardTitle>
            <div className='flex flex-row gap-4 place-items-center'>
              <h3>Schedule #{props.index + 1}</h3>
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button size='icon' variant='outline' className='h-6 w-6'>
                    <TbTrash />
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                    <AlertDialogDescription>
                      This action cannot be undone. This will delete Schedule #
                      {props.index + 1} from the Trip.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction onClick={props.onDelete}>
                      Continue
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </div>
          </CardTitle>
        </CardHeader>
      )}
      <MapPreview
        schedule={props.form.watch(`schedules.${props.index}`)}
        className='absolute top-4 right-4'
      />
      <CardContent
        className={cn('flex flex-col gap-4', props.hideTitle && 'mt-6')}
      >
        <FormField
          control={props.form.control}
          name={`schedules.${props.index}.riderId`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Rider *</FormLabel>
              <FormControl>
                <RiderInput
                  {...field}
                  fieldClassName='w-64 h-8'
                  onChange={rider => field.onChange(rider.id)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={props.form.control}
          name={`schedules.${props.index}.brokerId`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Broker *</FormLabel>
              <FormControl>
                <BrokerInput
                  {...field}
                  fieldClassName='w-64 h-8'
                  onChange={broker => field.onChange(broker.id)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={props.form.control}
          name={`schedules.${props.index}.status`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Status *</FormLabel>
              <FormControl>
                <EnumInput
                  enum={ScheduleStatus}
                  required
                  disabled={props.created}
                  fieldClassName='w-64 h-8'
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={props.form.control}
          name={`schedules.${props.index}.mobility`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Mobility *</FormLabel>
              <FormControl>
                <EnumInput
                  enum={Mobility}
                  required
                  fieldClassName='w-64 h-8'
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className='flex flex-row gap-4 place-items-center'>
          <FormField
            control={props.form.control}
            name={`schedules.${props.index}.from`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>From *</FormLabel>
                <FormControl>
                  <LocationInput
                    required
                    fieldClassName='w-64 h-8'
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <TbChevronRight className='self-end mb-2' />
          <FormField
            control={props.form.control}
            name={`schedules.${props.index}.to`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>To *</FormLabel>
                <FormControl>
                  <LocationInput
                    required
                    fieldClassName='w-64 h-8'
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className='flex flex-row gap-4 place-items-center'>
          <FormField
            control={props.form.control}
            name={`schedules.${props.index}.pickupTime`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Pickup Time *</FormLabel>
                <FormControl>
                  <DateInput
                    type='dateAndTime'
                    required
                    {...field}
                    onBlur={value => {
                      if (
                        value >
                        props.form.getValues(
                          `schedules.${props.index}.dropoffTime`
                        )
                      )
                        props.form.setValue(
                          `schedules.${props.index}.dropoffTime`,
                          value
                        )
                    }}
                    onChange={value => {
                      field.onChange(value)
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <TbChevronRight className='self-end mb-2' />
          <FormField
            control={props.form.control}
            name={`schedules.${props.index}.dropoffTime`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Dropoff Time *</FormLabel>
                <FormControl>
                  <DateInput
                    type='dateAndTime'
                    required
                    {...field}
                    onBlur={value => {
                      if (
                        value <
                        props.form.getValues(
                          `schedules.${props.index}.pickupTime`
                        )
                      )
                        props.form.setValue(
                          `schedules.${props.index}.pickupTime`,
                          value
                        )
                    }}
                    onChange={value => {
                      field.onChange(value)
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={props.form.control}
          name={`schedules.${props.index}.dispatcherNotes`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Dispatcher Notes</FormLabel>
              <FormControl>
                <Input
                  className='w-full h-8'
                  placeholder='Notes that are visible only for dispatcher.'
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={props.form.control}
          name={`schedules.${props.index}.notes`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Notes</FormLabel>
              <FormControl>
                <Input
                  className='w-full h-8'
                  placeholder='Notes that are visible for driver.'
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {signature.data !== undefined && (
          <Card className='flex flex-row place-items-center justify-between gap-4'>
            <CardHeader>
              <CardTitle>Signature</CardTitle>
              <CardDescription>
                Rider's signature that Driver has uploaded.
              </CardDescription>
            </CardHeader>
            <CardContent className='p-0 mr-4'>
              {signature.isLoading ? (
                <Spinner />
              ) : (
                <img className='h-24 dark:invert' src={signature.data} />
              )}
            </CardContent>
          </Card>
        )}
        <Card>
          <CardHeader className='flex flex-row place-items-center gap-2'>
            <CardTitle>Guests</CardTitle>
            <Button
              size='icon'
              variant='outline'
              type='button'
              className='h-6 w-6'
              onClick={handleAddGuest}
            >
              <TbPlus />
            </Button>
          </CardHeader>
          {props.form.watch(`schedules.${props.index}.guests`)!?.length > 0 && (
            <CardContent className='flex flex-row w-[500px] flex-wrap gap-2'>
              {props.form
                .watch(`schedules.${props.index}.guests`)
                ?.map((_, i) => (
                  <FormField
                    control={props.form.control}
                    key={`guests.${i}`}
                    name={`schedules.${props.index}.guests.${i}`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Guest #{i + 1}</FormLabel>
                        <FormControl>
                          <div className='flex flex-row gap-2'>
                            <EnumInput
                              required
                              fieldClassName='w-40'
                              enum={Mobility}
                              {...field}
                            />
                            <Button
                              size='icon'
                              variant='outline'
                              type='button'
                              className='w-8 h-8'
                              onClick={() => handleDeleteGuest(i)}
                            >
                              <TbTrash />
                            </Button>
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ))}
            </CardContent>
          )}
        </Card>
      </CardContent>
    </Card>
  )
}
export default ScheduleForm
