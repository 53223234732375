import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { useCalled } from '@/hooks/mutations/useCalled'
import { useSetScheduleStatus } from '@/hooks/mutations/usetSetScheduleStatus'
import { formatScheduleStatus } from '@/lib/utils'
import { ISchedule, ITrip, SettableScheduleStatus } from '@/types/trips.type'
import { CellContext } from '@tanstack/react-table'
import { FC, useState } from 'react'
import { TbAnchor, TbDots, TbPencil, TbPhone, TbUser } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

const TripActions: FC<CellContext<any, any>> = (
  props: CellContext<any, any>
) => {
  const navigate = useNavigate()

  const { mutate: setStatus } = useSetScheduleStatus()
  const { mutate: setCalled } = useCalled()

  const [dialogStatus, setDialogStatus] = useState<
    undefined | SettableScheduleStatus
  >()

  const parent = props.row.getParentRow()?.original as ITrip
  const original = props.row.original as ISchedule

  if (!parent) return

  return (
    <>
      <Dialog
        onOpenChange={(value) => setDialogStatus(undefined)}
        open={!!dialogStatus}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              Change status to {formatScheduleStatus(dialogStatus!)}
            </DialogTitle>
            <DialogDescription>
              You can update the status of this trip only for this trip or for
              all "Assigned" or "Unassigned" trips of this rider for selected
              day. You can change status later.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              onClick={() => {
                setStatus({
                  schedule: original,
                  forAllRiderTrips: true,
                  status: dialogStatus!
                })
                setDialogStatus(undefined)
              }}
              variant='destructive'
            >
              All trips of this rider
            </Button>
            <Button
              onClick={() => {
                setStatus({
                  schedule: original,
                  forAllRiderTrips: false,
                  status: dialogStatus!
                })
                setDialogStatus(undefined)
              }}
            >
              This trip only
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant='ghost'
            className='h-8 w-8 p-0'
            onClick={event => event.stopPropagation()}
          >
            <TbDots className='h-4 w-4' />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align='end'>
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => navigate(`/trips/${parent.id}`)}>
            <TbPencil className='mr-2 h-4 w-4' />
            Edit Trip
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => navigate(`/drivers/${parent.driverId}`)}
            disabled={!!!parent.driverId}
          >
            <TbUser className='mr-2 h-4 w-4' />
            Edit Driver
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => navigate(`/riders/${original.riderId}`)}
          >
            <TbAnchor className='mr-2 h-4 w-4' />
            Edit Rider
          </DropdownMenuItem>
          {original.willCall && (
            <DropdownMenuItem onClick={() => setCalled(original)}>
              <TbPhone className='mr-2 h-4 w-4' />
              Person Called
            </DropdownMenuItem>
          )}
          <DropdownMenuSeparator />
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>Status</DropdownMenuSubTrigger>
            <DropdownMenuSubContent>
              <DropdownMenuRadioGroup
                onValueChange={value => {
                  const status = value as SettableScheduleStatus

                  if (
                    value === SettableScheduleStatus.Canceled ||
                    value === SettableScheduleStatus.NoShow
                  )
                    return setDialogStatus(status)

                  return setStatus({
                    schedule: original,
                    status: status
                  })
                }}
                value={original.status}
              >
                <DropdownMenuRadioItem value={SettableScheduleStatus.NoShow}>
                  No Show
                </DropdownMenuRadioItem>
                <DropdownMenuRadioItem
                  value={SettableScheduleStatus.ArrivedAtDO}
                >
                  Arrived (DO)
                </DropdownMenuRadioItem>
                <DropdownMenuRadioItem
                  value={SettableScheduleStatus.ArrivedAtPU}
                >
                  Arrived (PU)
                </DropdownMenuRadioItem>
                <DropdownMenuRadioItem value={SettableScheduleStatus.Canceled}>
                  Canceled
                </DropdownMenuRadioItem>
                <DropdownMenuRadioItem value={SettableScheduleStatus.Completed}>
                  Completed
                </DropdownMenuRadioItem>
                <DropdownMenuRadioItem value={SettableScheduleStatus.InRoute}>
                  In Route
                </DropdownMenuRadioItem>
                <DropdownMenuRadioItem value={SettableScheduleStatus.InTransit}>
                  In Transit
                </DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
            </DropdownMenuSubContent>
          </DropdownMenuSub>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}
export default TripActions
