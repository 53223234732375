import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { useAppSelector } from '@/hooks/useAppSelector'
import { CellContext } from '@tanstack/react-table'
import { FC } from 'react'
import { TbClipboard } from 'react-icons/tb'

const AddressCell: FC<CellContext<any, any>> = (
  props: CellContext<any, any>
) => {
  const { toast } = useToast()
  const showClipboard = useAppSelector(
    state => state.settingsState.showClipboard
  )

  return (
    props.getValue() && (
      <div className='flex gap-2 place-items-center'>
        {window.isSecureContext && showClipboard && (
          <Button
            className='h-4 w-4'
            size='icon'
            variant='ghost'
            onClick={async event => {
              event.stopPropagation()
              await navigator.clipboard.writeText(props.getValue()).then(() =>
                toast({
                  title: 'Copied!',
                  description: `${props.getValue()} was copied to your clipboard.`,
                  duration: 750
                })
              )
            }}
          >
            <TbClipboard />
          </Button>
        )}
        <span className='text-xs'>{props.getValue()}</span>
      </div>
    )
  )
}
export default AddressCell
