import { useAvatar } from '@/hooks/queries/useAvatar'
import { IUser } from '@/types/users.type'
import { FC, HTMLAttributes } from 'react'
import { Avatar, AvatarFallback, AvatarImage } from './avatar'
import Spinner from './Spinner'

interface IUserAvatarProps extends HTMLAttributes<HTMLDivElement> {
  user: IUser
}

const UserAvatar: FC<IUserAvatarProps> = (props: IUserAvatarProps) => {
  const avatar = useAvatar(props.user.id, props.user.isAvatar)

  return (
    <Avatar {...props}>
      <AvatarImage src={`${avatar.data}`} />
      <AvatarFallback className='select-none'>
        {avatar.isLoading ? <Spinner /> : props.user.username[0].toUpperCase()}
      </AvatarFallback>
    </Avatar>
  )
}
export default UserAvatar
