import { IBroker } from '@/types/brokers.type'
import { api } from './axios.interceptor'

class BrokersService {
  async getAll() {
    const response = await api.get<IBroker[]>('/brokers')

    return response
  }

  async getById(id: number) {
    const response = await api.get<IBroker>(`/brokers/${id}`)

    return response
  }

  async create(broker: IBroker) {
    const response = await api.post<IBroker>('/brokers', broker)

    return response
  }

  async delete(id: number) {
    const response = await api.delete<void>(`/brokers/${id}`)

    return response
  }

  async update(id: number, data: Partial<IBroker>) {
    const response = await api.patch<IBroker>(`/brokers/${id}`, data)

    return response
  }
}

const brokersService = new BrokersService()

export default brokersService
