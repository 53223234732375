import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/ui/command'
import { Label } from '@/components/ui/label'
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover'
import { useRider } from '@/hooks/queries/useRider'
import { useRidersSearch } from '@/hooks/queries/useRidersSearch'
import { cn } from '@/lib/utils'
import { IRider } from '@/types/riders.type'
import { FC, useState } from 'react'
import { TbCheck, TbSearch } from 'react-icons/tb'
import { Link } from 'react-router-dom'
import RiderPreview from '../previews/RiderPreview'
import Spinner from '../Spinner'

interface IRiderSearchProps {
  value?: number
  size?: 'sm' | 'md' | 'lg'
  title?: string
  id?: string
  required?: boolean
  fieldClassName?: string
  popoverClassName?: string
  onChange?: (data: IRider) => any
}

const RiderInput: FC<IRiderSearchProps> = (props: IRiderSearchProps) => {
  const [input, setInput] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false)

  const rider = useRider(props.value as number)

  const search = useRidersSearch(input)

  return (
    <div className={cn('flex flex-col gap-2')}>
      <Label htmlFor={props.id}>
        {props.title ?? ''}
        {props.required && ' *'}
      </Label>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant='outline'
            role='combobox'
            className={cn('h-8 w-64 justify-between', props.fieldClassName)}
            aria-expanded={open}
          >
            <span className={'overflow-hidden whitespace-nowrap text-ellipsis'}>
              {rider.data
                ? `${rider.data.firstName} ${rider.data.middleName ? `${rider.data.middleName} ` : ''}${rider.data.lastName}`
                : 'Search for riders...'}
            </span>
            <TbSearch />
          </Button>
        </PopoverTrigger>
        <PopoverContent className={cn('w-64 p-0', props.popoverClassName)}>
          <Command shouldFilter={false}>
            <CommandInput
              placeholder='Search for riders...'
              value={input}
              onValueChange={setInput}
            />
            <CommandList>
              <CommandGroup>
                {input && !search.isLoading && (
                  <CommandEmpty>
                    <Link to='/riders/create'>
                      No results found.
                      <br />
                      Click to create a new Rider.
                    </Link>
                  </CommandEmpty>
                )}
                {search.isLoading && (
                  <div className='flex justify-center my-2'>
                    <Spinner />
                  </div>
                )}
                {search.data?.map(rider => (
                  <CommandItem
                    key={rider.id}
                    onSelect={() => {
                      props.onChange?.(rider)
                      setOpen(false)
                    }}
                  >
                    <TbCheck
                      className={`w-4 h-4 mr-2 ${props.value === rider.id ? 'opacity-100' : 'opacity-0'}`}
                    />
                    <RiderPreview value={rider} />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default RiderInput
