import { FC } from 'react'
import {
  TbAdjustments,
  TbAnchor,
  TbBuildingSkyscraper,
  TbCalendar,
  TbClock,
  TbLayoutDashboard,
  TbSettings,
  TbShirt,
  TbTruck,
  TbUser,
  TbUserDollar,
  TbUserShield
} from 'react-icons/tb'
import { Link } from 'react-router-dom'
import SearchBar from './SearchBar'
import LeftBarButton from './SideBarButton'
import LeftBarCategory from './SideBarCategory'

const SideBar: FC = () => {
  return (
    <aside className='flex flex-col h-screen transition-all border-r w-full min-w-[100px] sm:min-w-[200px] p-4 pt-8 gap-4 overflow-hidden overflow-y-auto'>
      <Link
        to='/'
        className='relative flex flex-row w-full gap-2 justify-center sm:justify-start place-items-center mb-4'
      >
        <img src='/favicon.png' alt='Logo' height={16} width={32} />
        <p className='font-medium hidden sm:block'>ManageNEMT</p>
      </Link>
      <SearchBar />
      <LeftBarButton icon={TbLayoutDashboard} href='/' title='Dashboard' />
      <LeftBarButton icon={TbClock} href='/dispatch' title='Dispatch' />
      <LeftBarButton icon={TbShirt} href='/shifts' title='Shifts' />
      <LeftBarButton icon={TbCalendar} href='/trips' title='Trips' />
      <LeftBarCategory icon={TbBuildingSkyscraper} title='Company'>
        <LeftBarButton icon={TbTruck} href='/vehicles' title='Vehicles' />
        <LeftBarButton icon={TbUser} href='/drivers' title='Drivers' />
        <LeftBarButton icon={TbAnchor} href='/riders' title='Riders' />
      </LeftBarCategory>
      <LeftBarCategory icon={TbSettings} title='Settings'>
        <LeftBarButton icon={TbUserDollar} href='/brokers' title='Brokers' />
        <LeftBarButton icon={TbUserShield} href='/admins' title='Admins' />
        <LeftBarButton icon={TbAdjustments} href='/settings' title='Company' />
      </LeftBarCategory>
    </aside>
  )
}

export default SideBar
