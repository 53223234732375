import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent
} from '@/components/ui/chart'
import { Skeleton } from '@/components/ui/skeleton'
import { format } from 'date-fns'
import { FC, HTMLAttributes } from 'react'
import { CartesianGrid, Line, LineChart, XAxis } from 'recharts'
import { useTripsStats } from '../hooks/useTripsStats'

const TodayOverview: FC<HTMLAttributes<HTMLDivElement>> = (
  props: HTMLAttributes<HTMLDivElement>
) => {
  const statistics = useTripsStats('day')

  return (
    <Card {...props}>
      <CardHeader>
        <CardTitle>Today Overview</CardTitle>
        <CardDescription>
          Trips statistics for {format(new Date(), 'MM.dd (EEEE)')}.
        </CardDescription>
      </CardHeader>
      <CardContent className='min-h-64'>
        {statistics.data ? (
          <ChartContainer
            className='w-full max-h-40'
            config={{
              total: {
                label: 'Trips',
                theme: {
                  light: '#222',
                  dark: '#ddd'
                }
              }
            }}
          >
            <LineChart accessibilityLayer data={statistics.data}>
              <CartesianGrid vertical={false} />
              <Line
                dataKey='total'
                type='natural'
                stroke='var(--color-total)'
                strokeWidth={2}
                dot={{
                  fill: 'var(--color-total)'
                }}
                activeDot={{
                  r: 6
                }}
              />
              <XAxis
                dataKey='date'
                tickLine={false}
                axisLine={false}
                minTickGap={0}
                className='translate-y-4'
                tickFormatter={value => format(value, 'HH:00')}
              />
              <ChartTooltip
                cursor={false}
                content={
                  <ChartTooltipContent
                    labelFormatter={value => format(value, 'HH:00')}
                  />
                }
              />
            </LineChart>
          </ChartContainer>
        ) : (
          <div className='flex flex-col gap-4 w-full min-h-40'>
            <Skeleton className='w-full h-28' />
            <div className='w-full flex flex-row justify-between gap-4 h-6'>
              <Skeleton className='w-16' />
              <Skeleton className='w-16' />
              <Skeleton className='w-16' />
              <Skeleton className='w-16' />
              <Skeleton className='w-16' />
              <Skeleton className='w-16' />
              <Skeleton className='w-16' />
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  )
}
export default TodayOverview
