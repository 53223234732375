import Table, { ICustomizableTableProps } from '@/components/ui/Table/Table'
import { useCreatePreset } from '@/hooks/mutations/useCreatePreset'
import { IImportProperties } from '@/types/import.type'
import { FC } from 'react'
import { useDeletePreset } from '../../hooks/useDeletePreset'
import { useRenamePreset } from '../../hooks/useRenamePreset'
import DeleteDialog from './DeleteDialog'
import DuplicateDialog from './DuplicateDialog'
import RenameDialog from './RenameDialog'

interface ITablePreset {
  name: string
  data: IImportProperties
}

const PresetsTable: FC<ICustomizableTableProps<ITablePreset>> = (
  props: ICustomizableTableProps<ITablePreset>
) => {
  const { mutate: renamePreset } = useRenamePreset()
  const { mutate: deletePreset } = useDeletePreset()
  const { mutate: createPreset } = useCreatePreset()

  return (
    <Table
      {...props}
      className='w-[1000px] max-w-full'
      notFoundText='No presets found.'
      disablePagination
      columns={[
        {
          header: 'Name',
          accessorKey: 'name',
          size: 900,
          minSize: 800,
          maxSize: 1000,
          cell: props => (
            <div>
              <span>{props.getValue()}</span>
            </div>
          )
        },
        {
          header: 'Actions',
          accessorKey: 'actions',
          enableSorting: false,
          size: 200,
          minSize: 200,
          maxSize: 200,
          cell: props => (
            <div className='flex flex-row gap-2'>
              <RenameDialog
                initialName={props.row.original.name}
                onAccept={name =>
                  renamePreset({
                    name: props.row.original.name,
                    updatedName: name
                  })
                }
              />
              <DeleteDialog
                onAccept={() => deletePreset(props.row.original.name)}
              />
              <DuplicateDialog
                onAccept={() =>
                  createPreset({
                    name: `${props.row.original.name} (Copy)`,
                    data: props.row.original.data
                  })
                }
              />
            </div>
          )
        }
      ]}
    />
  )
}
export default PresetsTable
