import { Button } from '@/components/ui/button'
import {
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel
} from '@/components/ui/context-menu'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from '@/components/ui/hover-card'
import Table, { ICustomizableTableProps } from '@/components/ui/Table/Table'
import { IDriver } from '@/types/drivers.type'
import { Row } from '@tanstack/react-table'
import { format } from 'date-fns'
import { FC } from 'react'
import { TbDots, TbMail } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import DriverDeleteDialog from './DriverDeleteDialog'
import { useReinvite } from '../../hooks/useReinvite'

const DriversTable: FC<ICustomizableTableProps<IDriver>> = (
  props: ICustomizableTableProps<IDriver>
) => {
  const navigate = useNavigate()
  const { mutate: reinvite } = useReinvite()

  return (
    <Table
      {...props}
      initialState={{
        sorting: [
          {
            desc: false,
            id: 'name'
          }
        ]
      }}
      contextMenu={(driver: IDriver) => (
        <ContextMenuContent>
          <ContextMenuLabel>Actions</ContextMenuLabel>
          <ContextMenuItem onClick={() => navigate(`/drivers/${driver.id}`)}>
            Edit
          </ContextMenuItem>
          <DriverDeleteDialog driver={driver}>
            <ContextMenuItem>Delete</ContextMenuItem>
          </DriverDeleteDialog>
        </ContextMenuContent>
      )}
      columns={[
        {
          id: 'name',
          header: 'Name',
          accessorFn: data => data,
          filterFn: (row: Row<IDriver>, columnId, value) => {
            if (!value) return true

            const includes = (toCompare: string) =>
              toCompare.toLowerCase().includes(value.toLowerCase())

            return (
              includes(row.original.user.firstName) ||
              includes(row.original.user.middleName || '') ||
              includes(row.original.user.lastName)
            )
          },
          cell: props => (
            <span>
              {props.getValue().user.firstName}{' '}
              {props.getValue().user.middleName}{' '}
              {props.getValue().user.lastName}
            </span>
          )
        },
        {
          accessorKey: 'email',
          header: 'Email',
          accessorFn: data => data.user,
          cell: props => (
            <div className='flex flex-row place-items-center gap-2'>
              <span className='break-all text-sm'>
                {props.getValue().email}
              </span>
              {!props.getValue().registered && (
                <HoverCard>
                  <HoverCardTrigger>
                    <Button
                      className='h-8 w-8'
                      variant='outline'
                      size='icon'
                      onClick={() => reinvite(props.getValue().id)}
                    >
                      <TbMail />
                    </Button>
                  </HoverCardTrigger>
                  <HoverCardContent>
                    Resend invitation link to {props.getValue().email}
                  </HoverCardContent>
                </HoverCard>
              )}
            </div>
          )
        },
        {
          accessorKey: 'phone',
          header: 'Phone',
          accessorFn: data => data.user.phone,
          cell: props => <span>{props.getValue()}</span>
        },
        {
          accessorKey: 'license',
          header: 'License',
          accessorFn: data => data,
          cell: props => (
            <span>
              {props.getValue().licenseType &&
                `(${props.getValue().licenseType}) `}
              {props.getValue().licenseExpires &&
                `${props.getValue().licenseNumber}, `}
              {props.getValue().licenseState &&
                `${props.getValue().licenseState}, `}
              {props.getValue().licenseExpires &&
                `${format(props.getValue().licenseExpires, 'MM.dd.yyyy')}`}
            </span>
          )
        },
        {
          accessorKey: 'notes',
          header: 'Notes',
          cell: props => <span className='text-xs'>{props.getValue()}</span>
        },
        {
          id: 'actions',
          size: 50,
          minSize: 50,
          maxSize: 50,
          cell: props => (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant='ghost' className='h-8 w-8 p-0'>
                  <TbDots className='h-4 w-4' />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                onClick={event => event.stopPropagation()}
                align='end'
              >
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuItem
                  onClick={() => navigate(`/drivers/${props.row.original.id}`)}
                >
                  Edit
                </DropdownMenuItem>
                <DriverDeleteDialog driver={props.row.original}>
                  <DropdownMenuItem>Delete</DropdownMenuItem>
                </DriverDeleteDialog>
              </DropdownMenuContent>
            </DropdownMenu>
          )
        }
      ]}
      data={props.data}
    />
  )
}

export default DriversTable
