import tripsService from '@/services/trips.service'
import { ISchedule } from '@/types/trips.type'
import { useQuery } from '@tanstack/react-query'

export const useSignature = (schedule: ISchedule) =>
  useQuery({
    enabled: !!schedule.id && schedule.isSignatureUploaded,
    queryFn: async () => await tripsService.getSignature(schedule.id),
    select: response => response.data,
    retry: 0,
    queryKey: [`signature`, schedule.id]
  })
