import {
  TableCell as PrimitiveTableCell,
  TableRow as PrimitiveTableRow
} from '@/components/ui/table'
import { DNDType } from '@/types/dnd.type'
import { flexRender, Row } from '@tanstack/react-table'
import { FC } from 'react'
import { DragSourceMonitor, useDrag } from 'react-dnd'

interface ITableRowProps<T> {
  row: Row<T>
  dnd?: {
    type: DNDType
    deps?: unknown[]
  }
  onContextMenu?: React.MouseEventHandler<HTMLTableRowElement>
  onSelectionChange?: (data: T | undefined, row?: Row<T>) => any
}

const TableRow: FC<ITableRowProps<any>> = <T,>(props: ITableRowProps<T>) => {
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: typeof props.dnd?.type,
      item: props.row.getParentRow()
        ? props.row.getParentRow()?.original
        : props.row.original,
      options: {
        dropEffect: 'copy',

        /*
          We are doing this strange thing becuase of bug in react-dnd 
           https://github.com/react-dnd/react-dnd/issues/3345
        */
        ...Object.assign({}, props.dnd?.deps)
      },
      collect: (montior: DragSourceMonitor<unknown, unknown>) => ({
        opacity: montior.isDragging() ? 0.4 : 1
      })
    }),
    props.dnd?.deps
  )

  return (
    <PrimitiveTableRow
      data-state={props.row.getIsSelected() && 'selected'}
      ref={props.dnd ? dragRef : undefined}
      onContextMenu={props.onContextMenu}
      className={`${props.row.subRows.length === 1 && 'hidden'} border-t ${(props.row.subRows.length > 1 || props.row.getParentRow()!?.subRows.length > 1) && 'border-y-0 border-l-2 bg-green-50/50 dark:bg-green-50/5'}`}
      key={`row-${props.row.id}`}
    >
      {props.row.getVisibleCells().map(cell => (
        <PrimitiveTableCell
          onClick={() => {
            props.row.toggleSelected(!props.row.getIsSelected())
            props.onSelectionChange?.(
              props.row.getIsSelected() ? undefined : props.row.original,
              props.row.getIsSelected() ? undefined : props.row
            )
          }}
          key={`cell-${cell.id}`}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </PrimitiveTableCell>
      ))}
    </PrimitiveTableRow>
  )
}
export default TableRow
