import { Toaster } from '@/components/ui/toaster'
import { ThemeProvider } from '@/providers/ThemeProvider'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { APIProvider } from '@vis.gl/react-google-maps'
import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { TooltipProvider } from './components/ui/tooltip'
import './index.css'
import { routes } from './routes'
import { persistor, store } from './store'

Sentry.init({
  dsn: 'https://6e71761202f5cea154d71e2947aa4044@sentry.managenemt.com/3',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  environment: import.meta.env.MODE,
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', 'https://panel.managenemt.com'],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

const router = createBrowserRouter(routes)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
      retry: 1,
      enabled: true
    }
  }
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <APIProvider apiKey={import.meta.env.VITE_GOOGLE_API_KEY}>
            <DndProvider backend={HTML5Backend}>
              <TooltipProvider delayDuration={200}>
                <ThemeProvider>
                  <Toaster />
                  <RouterProvider router={router} />
                </ThemeProvider>
              </TooltipProvider>
            </DndProvider>
          </APIProvider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>
)
