import ScheduleContextMenu from '@/components/tables/TripsTable/ScheduleContextMenu'
import ScheduleStatusBadge from '@/components/tables/TripsTable/ScheduleStatusBadge'
import TripActions from '@/components/tables/TripsTable/TripActions'
import {
  sortTripPlain,
  sortTripRiders,
  sortTripTimes
} from '@/components/tables/TripsTable/tripUtils'
import RiderPreview from '@/components/ui/previews/RiderPreview'
import TimePreview from '@/components/ui/previews/TimePreview'
import AddressCell from '@/components/ui/Table/AddressCell'
import Table, { ICustomizableTableProps } from '@/components/ui/Table/Table'
import { useToast } from '@/components/ui/use-toast'
import { useAssignTrip } from '@/hooks/mutations/useAssignTrip'
import { formatAddress } from '@/lib/utils'
import { IDriver } from '@/types/drivers.type'
import { ISchedule, ITrip } from '@/types/trips.type'
import { Row } from '@tanstack/react-table'
import { FC } from 'react'
import { TbChevronDown, TbChevronUp } from 'react-icons/tb'

interface IDispatchPageScheduleTableProps
  extends ICustomizableTableProps<ITrip> {
  selectedDriver: IDriver | undefined
}

const DispatchPageAssignedScheduleTable: FC<IDispatchPageScheduleTableProps> = (
  props: IDispatchPageScheduleTableProps
) => {
  const { toast } = useToast()

  const assignSchedule = useAssignTrip()

  return (
    <Table
      {...props}
      getSubRowsFn={trip => trip.schedules}
      disablePagination
      contextMenu={(schedule: ISchedule, row: Row<any>) => {
        return (
          <ScheduleContextMenu
            schedule={schedule as ISchedule}
            driver={row.getParentRow()?.original.driver}
          />
        )
      }}
      dnd={{
        draggable: true,
        droppable: !!props.selectedDriver,
        type: 'trip',
        deps: [props.selectedDriver, props.data],
        onDrop: (item: ITrip) => {
          if (!props.selectedDriver)
            return toast({
              title: 'Error!',
              description: `Select driver before Drag&Drop.`
            })

          if (item.driver)
            toast({
              title: 'Error!',
              description: `Trip ${item.id} is already assigned to #${item.driver.user.firstName} ${item.driver.user.lastName}.`
            })

          assignSchedule.mutate({
            driver: props.selectedDriver,
            trip: item
          })
        }
      }}
      initialState={{
        sorting: [
          {
            id: 'pickupTime',
            desc: false
          }
        ]
      }}
      columns={[
        {
          header: '#',
          accessorKey: 'expander',
          enableSorting: false,
          accessorFn: data => data.status,
          cell: props => (
            <div>
              {props.row.getCanExpand() && (
                <button
                  onClick={props.row.getToggleExpandedHandler()}
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  {props.row.getIsExpanded() ? (
                    <TbChevronUp />
                  ) : (
                    <TbChevronDown />
                  )}
                </button>
              )}
              {props.getValue() && (
                <ScheduleStatusBadge value={props.getValue()} />
              )}
            </div>
          )
        },
        {
          accessorKey: 'rider',
          header: 'Rider',
          accessorFn: data => data.rider,
          sortingFn: sortTripRiders,
          cell: props =>
            props.getValue() ? (
              <RiderPreview value={props.getValue()} />
            ) : (
              <span>{props.row.original.schedules.length} riders</span>
            )
        },
        {
          accessorKey: 'from',
          header: 'From',
          sortingFn: sortTripPlain,
          accessorFn: data => formatAddress(data.from, 'normal'),
          cell: props => <AddressCell {...props} />
        },
        {
          accessorKey: 'to',
          header: 'To',
          sortingFn: sortTripPlain,
          accessorFn: data => formatAddress(data.to, 'normal'),
          cell: props => <AddressCell {...props} />
        },
        {
          accessorKey: 'pickupTime',
          header: 'PU Time',
          accessorFn: data => data,
          sortingFn: sortTripTimes,
          cell: props => <TimePreview value={props.getValue()} time='pickup' />
        },
        {
          accessorKey: 'dropoffTime',
          header: 'DO Time',
          accessorFn: data => data,
          sortingFn: sortTripTimes,
          cell: props => <TimePreview value={props.getValue()} time='dropoff' />
        },
        {
          accessorKey: 'mobility',
          header: 'Mobility',
          accessorFn: data => data?.mobility,
          sortingFn: sortTripPlain,
          cell: props => <span>{props.getValue()}</span>
        },
        {
          id: 'actions',
          size: 50,
          minSize: 50,
          maxSize: 50,
          cell: props => <TripActions {...props} />
        }
      ]}
    />
  )
}
export default DispatchPageAssignedScheduleTable
