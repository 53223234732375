import { ITripAmount, ITripStat, TripsStatsPeriod } from '@/types/reports.type'
import { api } from './axios.interceptor'

class ReportsSerivce {
  async getTripsAmountByDays(days: number) {
    const response = await api.get<ITripAmount[]>(
      `/reports/tripsAmountByDays/${days}`
    )
    return response
  }

  async getTripsStats(timestamp: Date, amount: TripsStatsPeriod) {
    const response = await api.get<ITripStat[]>(
      `/reports/stats/trips/${amount}/${timestamp.toISOString()}`
    )
    return response
  }
}

const reportsService = new ReportsSerivce()

export default reportsService
