import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import TextInput from '@/components/ui/inputs/TextInput'
import { FC, useState } from 'react'
import { TbPencil } from 'react-icons/tb'

interface IRenameDialogProps {
  initialName: string
  onAccept?: (value: string) => void
}

const RenameDialog: FC<IRenameDialogProps> = (props: IRenameDialogProps) => {
  const [name, setName] = useState<string>(props.initialName || '')

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          title='Rename'
          variant='outline'
          size='icon'
          className='h-8 w-8'
        >
          <TbPencil />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Rename</DialogTitle>
          <DialogDescription>
            Change name of {props.initialName}.
          </DialogDescription>
        </DialogHeader>
        <TextInput value={name} onChange={setName} className='w-full' />
        {name === props.initialName && (
          <span className='text-xs text-muted-foreground'>
            Preset with this name already exists.
          </span>
        )}
        <DialogFooter>
          <DialogClose asChild>
            <Button
              disabled={name === props.initialName}
              onClick={() => props.onAccept?.(name)}
            >
              Save changes
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
export default RenameDialog
