import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import { useRequestMailChange } from '@/hooks/mutations/useRequestMailChange'
import { cn } from '@/lib/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { object, string } from 'yup'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from './form'
import { Input } from './input'

export interface IMailChangeDialog {
  id: number | 'me'
  className?: string
}

const MailChangeDialog: FC<IMailChangeDialog> = (props: IMailChangeDialog) => {
  const { mutate: changeMail } = useRequestMailChange()

  const [open, setOpen] = useState(false)

  const form = useForm<{ newEmail: string }>({
    resolver: yupResolver(
      object({
        newEmail: string()
          .email('Invalid email.')
          .required('Email is required.')
      })
    )
  })

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          type='button'
          variant='outline'
          className={cn('w-20 h-8', props.className)}
        >
          Change
        </Button>
      </DialogTrigger>
      <DialogContent>
        <Form {...form}>
          <DialogHeader>
            <DialogTitle>Change email</DialogTitle>
            <DialogDescription>
              Are you sure you want to change email? This action will send an
              verification message to new email address. After accepting it,
              address will be changed.
            </DialogDescription>
          </DialogHeader>
          <FormField
            control={form.control}
            name='newEmail'
            render={({ field }) => (
              <FormItem>
                <FormLabel>New email</FormLabel>
                <FormControl>
                  <Input autoComplete='off' className='w-full h-8' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <DialogFooter>
            <Button
              type='submit'
              onClick={form.handleSubmit(data =>
                changeMail(
                  {
                    id: props.id,
                    newEmail: data.newEmail
                  },
                  {
                    onSuccess: () => {
                      setOpen(false)
                    }
                  }
                )
              )}
            >
              Send
            </Button>
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
export default MailChangeDialog

