import { IVehicle } from '@/types/vehicles.type'
import { api } from './axios.interceptor'

class VehiclesService {
  async getAll(notUsedOnly?: boolean) {
    const response = await api.get<IVehicle[]>('/vehicles', {
      params: {
        notUsedOnly: notUsedOnly
      }
    })

    return response
  }

  async getById(id: number) {
    const response = await api.get<IVehicle>(`/vehicles/${id}`)

    return response
  }

  async search(input: string, notWorkingDay?: Date) {
    const response = await api.get<IVehicle[]>(`/vehicles/search/${input}`, {
      params: {
        notWorkingDay: notWorkingDay
      }
    })

    return response
  }

  async create(request: IVehicle) {
    const response = await api.post<IVehicle>('/vehicles', request)

    return response
  }

  async update(id: number, request: IVehicle) {
    const response = await api.patch<IVehicle>(`/vehicles/${id}`, request)

    return response
  }

  async delete(id: number) {
    const response = await api.delete<IVehicle>(`/vehicles/${id}`)

    return response
  }
}

const vehiclesService = new VehiclesService()

export default vehiclesService
