import { Button } from '@/components/ui/button'
import { IconType } from 'react-icons'
import { Link, useLocation } from 'react-router-dom'

interface ILeftBarButton {
  icon: IconType
  href: string
  title: string
}

const LeftBarButton = (props: ILeftBarButton) => {
  const location = useLocation()

  return (
    <Button variant='ghost' size='sm' className='justify-start p-2' asChild>
      <Link
        to={props.href}
        className={`flex gap-3 place-items-center justify-center sm:justify-start ${location.pathname === props.href && 'bg-accent'}`}
      >
        <props.icon className='h-6 w-6' />
        <h5 className='hidden sm:block text-md'>{props.title}</h5>
      </Link>
    </Button>
  )
}

export default LeftBarButton
