import ridersService from '@/services/riders.service'
import { useQuery } from '@tanstack/react-query'
import { useDebounce } from 'use-debounce'

export const useRidersSearch = (input: string, debounce = 200) => {
  const debounced = useDebounce(input, debounce, {
    equalityFn: (left, right) => left === right
  })

  return useQuery({
    queryKey: ['riderSearch', debounced[0]],
    enabled: !!input,
    queryFn: async () => await ridersService.search(input),
    select: response => response.data
  })
}
