import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import DriverPreview from '@/components/ui/previews/DriverPreview'
import RiderPreview from '@/components/ui/previews/RiderPreview'
import { Skeleton } from '@/components/ui/skeleton'
import { useDrivers } from '@/hooks/queries/useDrivers'
import { useTrips } from '@/hooks/queries/useTrips'
import { endOfToday, startOfToday } from 'date-fns'
import { uniqBy } from 'lodash'
import { FC } from 'react'
import CompletedToday from './elements/CompletedToday'
import TodayOverview from './elements/TodayOverview'
import TodaySchedules from './elements/TodaySchedules'
import WeekOverview from './elements/WeekOverview'

const DashboardPage: FC = () => {
  const trips = useTrips(startOfToday(), endOfToday())
  const drivers = useDrivers(true)

  const todayRiders = uniqBy(
    trips.data
      ?.map(trip => trip.schedules.map(schedule => schedule.rider))
      .flat(),
    element => element.id
  )

  return (
    <div className='flex flex-col gap-4 w-full h-max'>
      <div className='flex flex-col sm:flex-row w-full h-full sm:h-1/3 gap-4'>
        <TodayOverview className='w-full sm:w-4/5' />
        <CompletedToday className='w-full sm:w-1/5 overflow-x-auto' />
      </div>
      <div className='flex flex-col sm:flex-row w-full h-full gap-4'>
        <div className='flex flex-col w-full sm:w-3/4 h-full gap-4'>
          <div className='flex flex-col sm:flex-row gap-4 w-full'>
            <TodaySchedules className='w-full sm:w-1/3 overflow-x-auto' />
            <WeekOverview className='w-full sm:w-2/3' />
          </div>
        </div>
        <div className='flex flex-col w-full sm:w-1/4 h-full gap-4 overflow-y-hidden'>
          <Card className='w-full overflow-y-auto'>
            <CardHeader>
              <CardTitle>Drivers</CardTitle>
              <CardDescription>Working today drivers list</CardDescription>
            </CardHeader>
            <CardContent className='flex gap-4 flex-wrap'>
              {drivers.data ? (
                drivers.data.length ? (
                  drivers.data.map(driver => (
                    <DriverPreview key={`driver${driver.id}`} value={driver} />
                  ))
                ) : (
                  <span>No drivers working today.</span>
                )
              ) : (
                <>
                  <Skeleton className='w-28 h-4' />
                  <Skeleton className='w-16 h-4' />
                  <Skeleton className='w-20 h-4' />
                  <Skeleton className='w-16 h-4' />
                </>
              )}
            </CardContent>
          </Card>
          <Card className='w-full max-h-full overflow-y-auto'>
            <CardHeader>
              <CardTitle>Riders</CardTitle>
              <CardDescription>Today's riders list</CardDescription>
            </CardHeader>
            <CardContent className='flex gap-4 flex-wrap'>
              {trips.data ? (
                todayRiders.length ? (
                  todayRiders.map(rider => (
                    <RiderPreview key={`rider${rider.id}`} value={rider} />
                  ))
                ) : (
                  <span>No riders today.</span>
                )
              ) : (
                <>
                  <Skeleton className='w-28 h-4' />
                  <Skeleton className='w-16 h-4' />
                  <Skeleton className='w-20 h-4' />
                  <Skeleton className='w-16 h-4' />
                  <Skeleton className='w-40 h-4' />
                  <Skeleton className='w-24 h-4' />
                  <Skeleton className='w-20 h-4' />
                </>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default DashboardPage
