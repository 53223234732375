import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/ui/command'
import { Label } from '@/components/ui/label'
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover'
import { useDriver } from '@/hooks/queries/useDriver'
import { useDriversSearch } from '@/hooks/queries/useDriversSearch'
import { cn, formatName } from '@/lib/utils'
import { IDriver } from '@/types/drivers.type'
import { FC, useState } from 'react'
import { TbCheck, TbSearch } from 'react-icons/tb'
import DriverPreview from '../previews/DriverPreview'
import Spinner from '../Spinner'

interface IRiderSearchProps {
  value?: number
  size?: 'sm' | 'md' | 'lg'
  title?: string
  id?: string
  required?: boolean
  fieldClassName?: string
  notWorkingDay?: Date
  popoverClassName?: string
  onChange?: (data: IDriver) => any
}

const DriverInput: FC<IRiderSearchProps> = (props: IRiderSearchProps) => {
  const [input, setInput] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false)

  const driver = useDriver(props.value as number)

  const search = useDriversSearch(input, props.notWorkingDay)

  return (
    <div className={cn('flex flex-col gap-2')}>
      <Label htmlFor={props.id}>
        {props.title ?? ''}
        {props.required && ' *'}
      </Label>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant='outline'
            role='combobox'
            className={cn('h-8 w-64 justify-between', props.fieldClassName)}
            aria-expanded={open}
          >
            <span className={'overflow-hidden whitespace-nowrap text-ellipsis'}>
              {driver.data
                ? formatName(driver.data.user)
                : 'Search for drivers...'}
            </span>
            <TbSearch />
          </Button>
        </PopoverTrigger>
        <PopoverContent className={cn('w-64 p-0', props.popoverClassName)}>
          <Command shouldFilter={false}>
            <CommandInput
              placeholder='Search for drivers...'
              value={input}
              onValueChange={setInput}
            />
            <CommandList>
              <CommandGroup>
                <CommandEmpty className='flex h-8 place-items-center justify-center'>
                  <span className='text-sm'>No results found.</span>
                </CommandEmpty>
                {search.isLoading && (
                  <div className='flex justify-center my-2'>
                    <Spinner />
                  </div>
                )}
                {search.data?.map(driver => (
                  <CommandItem
                    key={driver.id}
                    onSelect={() => {
                      props.onChange?.(driver)
                      setOpen(false)
                    }}
                  >
                    <TbCheck
                      className={`w-4 h-4 mr-2 ${props.value === driver.id ? 'opacity-100' : 'opacity-0'}`}
                    />
                    <DriverPreview value={driver} />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default DriverInput
