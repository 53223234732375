import { useToast } from '@/components/ui/use-toast'
import { useAppSelector } from '@/hooks/useAppSelector'
import { formatAddress } from '@/lib/utils'
import { IAddress } from '@/types/address.type'
import { FC } from 'react'

interface IAddressLabelProps {
  value: IAddress
}

const AddressLabel: FC<IAddressLabelProps> = (props: IAddressLabelProps) => {
  const { toast } = useToast()
  const showClipboard = useAppSelector(
    state => state.settingsState.showClipboard
  )

  const formattedAddress = formatAddress(props.value)

  return (
    <span className='text-xs'>
      {formattedAddress}{' '}
      {window.isSecureContext && showClipboard && (
        <span
          className='h-6 w-6 cursor-pointer'
          onClick={async event => {
            event.stopPropagation()
            await navigator.clipboard.writeText(formattedAddress).then(() =>
              toast({
                title: 'Copied!',
                description: `${formattedAddress} was copied to your clipboard.`,
                duration: 750
              })
            )
          }}
        >
          (click to copy)
        </span>
      )}
    </span>
  )
}
export default AddressLabel

