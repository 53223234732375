import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator
} from '@/components/ui/breadcrumb'
import { FC, Fragment } from 'react'
import { useMatches } from 'react-router-dom'
import User from './User'

interface ITopBarProps {}

const TopBar: FC<ITopBarProps> = (props: ITopBarProps) => {
  const matches = useMatches()

  const crumbs = matches
    .filter(match => Boolean((match.handle as any)?.crumb))
    .map((match, index) => {
      const rawParam = Object.values(match.params)[0]

      if (!rawParam) return (match.handle as any).crumb()

      /* For example: /trips/create -> Trips > Create */
      const param = rawParam[0].toUpperCase() + rawParam.slice(1)

      return (match.handle as any).crumb(param)
    })

  return (
    <div className='relative flex flex-row gap-4 w-full h-8 min-h-8 max-h-8 mb-2 place-items-center'>
      <Breadcrumb>
        <BreadcrumbList>
          {crumbs.map((crumb, index) => (
            <Fragment key={index}>
              <BreadcrumbItem>
                <BreadcrumbLink asChild>{crumb}</BreadcrumbLink>
              </BreadcrumbItem>
              {index !== crumbs.length - 1 && (
                <BreadcrumbSeparator key={`${index}-separator`} />
              )}
            </Fragment>
          ))}
        </BreadcrumbList>
      </Breadcrumb>
      <User />
    </div>
  )
}
export default TopBar
