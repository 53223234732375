import {
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuRadioGroup,
  ContextMenuRadioItem,
  ContextMenuSeparator,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger
} from '@/components/ui/context-menu'
import { useCalled } from '@/hooks/mutations/useCalled'
import { useSetScheduleStatus } from '@/hooks/mutations/usetSetScheduleStatus'
import { IDriver } from '@/types/drivers.type'
import { ISchedule, ScheduleStatus } from '@/types/trips.type'
import { FC } from 'react'
import { TbAnchor, TbPencil, TbPhone, TbUser } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

interface ITripContextMenuProps {
  schedule: ISchedule
  driver?: IDriver
}

const ScheduleContextMenu: FC<ITripContextMenuProps> = (
  props: ITripContextMenuProps
) => {
  const navigate = useNavigate()

  const { mutate: setStatus } = useSetScheduleStatus()
  const { mutate: setCalled } = useCalled()

  return (
    <ContextMenuContent>
      <ContextMenuLabel>Actions</ContextMenuLabel>
      <ContextMenuItem
        onClick={() => navigate(`/trips/${props.schedule.tripId}`)}
      >
        <TbPencil className='mr-2 h-4 w-4' />
        Edit Trip
      </ContextMenuItem>
      <ContextMenuItem
        onClick={() => navigate(`/drivers/${props.driver?.id}`)}
        disabled={!!!props.driver}
      >
        <TbUser className='mr-2 h-4 w-4' />
        Edit Driver
      </ContextMenuItem>
      <ContextMenuItem
        onClick={() => navigate(`/riders/${props.schedule.riderId}`)}
      >
        <TbAnchor className='mr-2 h-4 w-4' />
        Edit Rider
      </ContextMenuItem>
      {props.schedule.willCall && (
        <ContextMenuItem onClick={() => setCalled(props.schedule)}>
          <TbPhone className='mr-2 h-4 w-4' />
          Person Called
        </ContextMenuItem>
      )}
      <ContextMenuSeparator />
      <ContextMenuSub>
        <ContextMenuSubTrigger>Status</ContextMenuSubTrigger>
        <ContextMenuSubContent>
          <ContextMenuRadioGroup
            onValueChange={value =>
              setStatus({
                schedule: props.schedule,
                status: ScheduleStatus[value as keyof typeof ScheduleStatus]
              })
            }
            value={props.schedule.status}
          >
            {props.driver ? (
              <ContextMenuRadioItem value={ScheduleStatus.Assigned}>
                Assigned
              </ContextMenuRadioItem>
            ) : (
              <ContextMenuRadioItem value={ScheduleStatus.Unassigned}>
                Unassigned
              </ContextMenuRadioItem>
            )}
            <ContextMenuRadioItem value={ScheduleStatus.NoShow}>
              No Show
            </ContextMenuRadioItem>
            <ContextMenuRadioItem value={ScheduleStatus.ArrivedAtDO}>
              Arrived (DO)
            </ContextMenuRadioItem>
            <ContextMenuRadioItem value={ScheduleStatus.ArrivedAtPU}>
              Arrived (PU)
            </ContextMenuRadioItem>
            <ContextMenuRadioItem value={ScheduleStatus.Canceled}>
              Canceled
            </ContextMenuRadioItem>
            <ContextMenuRadioItem value={ScheduleStatus.Completed}>
              Completed
            </ContextMenuRadioItem>
            <ContextMenuRadioItem value={ScheduleStatus.InRoute}>
              In Route
            </ContextMenuRadioItem>
            <ContextMenuRadioItem value={ScheduleStatus.InTransit}>
              In Transit
            </ContextMenuRadioItem>
          </ContextMenuRadioGroup>
        </ContextMenuSubContent>
      </ContextMenuSub>
    </ContextMenuContent>
  )
}
export default ScheduleContextMenu
