import { toast } from '@/components/ui/use-toast'
import ridersService from '@/services/riders.service'
import { IAPIError } from '@/types/common.type'
import { IRider } from '@/types/riders.type'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

export const useCreateOrUpdateRider = () => {
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async (data: IRider) => {
      if (!data.id) {
        toast({
          title: 'Creating...',
          description: `Creating new rider.`
        })

        return await ridersService.create(data)
      }

      toast({
        title: 'Updating...',
        description: `Updating rider #${data.id}.`
      })

      return await ridersService.update(data.id, data)
    },
    onSuccess: ({ data }) => {
      navigate(-1)

      toast({
        title: 'Saved!',
        description: `${data.firstName} ${data.lastName} (#${data.id}) was saved successfully.`
      })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}
