import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent
} from '@/components/ui/chart'
import { Skeleton } from '@/components/ui/skeleton'
import { useSchedules } from '@/hooks/queries/useSchedules'
import { ScheduleStatus } from '@/types/trips.type'
import { endOfToday, format, startOfToday } from 'date-fns'
import { countBy } from 'lodash'
import { FC, HTMLAttributes, useMemo } from 'react'
import { Pie, PieChart } from 'recharts'

const statusToColor: Record<ScheduleStatus, string> = {
  [ScheduleStatus.Unassigned]: '#737373',
  [ScheduleStatus.Assigned]: '#0d9488',
  [ScheduleStatus.Canceled]: '#ef4444',
  [ScheduleStatus.InRoute]: '#f97316',
  [ScheduleStatus.ArrivedAtPU]: '#3b82f6',
  [ScheduleStatus.NoShow]: '#27272a',
  [ScheduleStatus.InTransit]: '#eab308',
  [ScheduleStatus.ArrivedAtDO]: '#8b5cf6',
  [ScheduleStatus.Completed]: '#16a34a'
}

const TodaySchedules: FC<HTMLAttributes<HTMLDivElement>> = (
  props: HTMLAttributes<HTMLDivElement>
) => {
  const todaySchedules = useSchedules(startOfToday(), endOfToday())

  const data = useMemo(() => {
    if (todaySchedules.data) {
      const counted = countBy(todaySchedules.data, schedule => schedule.status)

      return Object.entries(counted).map(([key, value]) => ({
        status: key,
        amount: value,
        fill: statusToColor[key as ScheduleStatus]
      }))
    }
  }, [todaySchedules.data])

  return (
    <Card {...props}>
      <CardHeader>
        <CardTitle>Today Schedules</CardTitle>
        <CardDescription>
          Schedule statistics for {format(new Date(), 'MM.dd (EEEE)')}.
        </CardDescription>
      </CardHeader>
      <CardContent className='min-h-80 max-h-80'>
        {todaySchedules.data ? (
          <ChartContainer
            className='mx-auto aspect-square max-h-72'
            config={{
              amount: {
                label: 'Amount'
              },
              Unassigned: {
                label: 'Unassigned'
              },
              Assigned: {
                label: 'Assigned'
              },
              Canceled: {
                label: 'Canceled'
              },
              InRoute: {
                label: 'In Route'
              },
              ArrivedAtPU: {
                label: 'Arrived (PU)'
              },
              NoShow: {
                label: 'No Show'
              },
              InTransit: {
                label: 'In Transit'
              },
              ArrivedAtDO: {
                label: 'Arrived (DO)'
              },
              Completed: {
                label: 'Completed'
              }
            }}
          >
            <PieChart>
              <Pie
                data={data}
                dataKey='amount'
                label={({ payload, ...props }) => (
                  <text {...props} fill='hsla(var(--foreground))'>
                    {JSON.stringify(payload.amount)}
                  </text>
                )}
                labelLine={false}
              />
              <ChartTooltip
                content={<ChartTooltipContent nameKey='status' hideLabel />}
              />
              <ChartLegend
                className='translate-y-2 flex-wrap gap-2'
                content={<ChartLegendContent nameKey='status' />}
              />
            </PieChart>
          </ChartContainer>
        ) : (
          <Skeleton className='mx-auto h-40 w-40 rounded-full' />
        )}
      </CardContent>
      <CardFooter className='justify-center text-muted-foreground text-xs h-4'>
        <span>Total schedules: </span>
        {todaySchedules.data ? (
          <b className='ml-1'>{todaySchedules.data.length}</b>
        ) : (
          <Skeleton className='ml-2 h-4 w-4' />
        )}
      </CardFooter>
    </Card>
  )
}
export default TodaySchedules
