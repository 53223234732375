import { useToast } from '@/components/ui/use-toast'
import usersService from '@/services/users.service'
import { IAPIError } from '@/types/common.type'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

export const useChangeEmail = () => {
  const { toast } = useToast()
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async (token: string) => {
      return await usersService.changeEmail(token)
    },
    onSuccess: () => {
      toast({
        title: 'Success!',
        description: 'Your email has been changed successfully.'
      })

      navigate('/login')
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}

