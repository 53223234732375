import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import DateInput from '@/components/ui/inputs/dateInput/DateInput'
import DriverInput from '@/components/ui/inputs/DriverInput'
import VehicleInput from '@/components/ui/inputs/VehicleInput'
import Spinner from '@/components/ui/Spinner'
import { useShift } from '@/hooks/queries/useShift'
import { ISchemaShift, IShift, shiftSchema } from '@/types/shifts.type'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  endOfDay,
  getHours,
  getMinutes,
  setHours,
  setMinutes,
  startOfDay
} from 'date-fns'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { TbPencil, TbPlus, TbTrash } from 'react-icons/tb'
import { useParams } from 'react-router-dom'
import { useCreateOrUpdateShift } from './hooks/useCreateOrUpdateShift'

const ShiftForm: FC = () => {
  const shiftId = Number(useParams().shiftId)

  const shift = useShift(shiftId)

  const form = useForm<ISchemaShift>({
    resolver: yupResolver(shiftSchema),
    defaultValues: {
      date: new Date(),
      breaks: [],
      workingTime: {
        start: startOfDay(new Date()),
        end: endOfDay(new Date())
      }
    }
  })

  useEffect(() => form.reset(shift.data), [shift.data])

  const { mutate: submit } = useCreateOrUpdateShift()

  return (
    <Form {...form}>
      {shift.isLoading ? (
        <Spinner />
      ) : (
        <form
          className='flex flex-col gap-4'
          onSubmit={form.handleSubmit(data => submit(data as IShift))}
        >
          <FormField
            control={form.control}
            name='date'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Date *</FormLabel>
                <FormControl>
                  <DateInput
                    className='w-64 h-8'
                    type='date'
                    {...field}
                    onChange={value => {
                      field.onChange(value)
                      form.setValue('workingTime.start', startOfDay(value))
                      form.setValue('workingTime.end', endOfDay(value))
                      form.setValue(
                        'breaks',
                        form.getValues('breaks').map(break_ => {
                          return {
                            ...break_,
                            start: setMinutes(
                              setHours(value, getHours(break_.start)),
                              getMinutes(break_.start)
                            ),
                            end: setMinutes(
                              setHours(value, getHours(break_.end)),
                              getMinutes(break_.end)
                            )
                          }
                        })
                      )
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='driverId'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Driver *</FormLabel>
                <FormControl>
                  <DriverInput
                    {...field}
                    fieldClassName='w-64 h-8'
                    notWorkingDay={form.getValues('date')}
                    onChange={driver => field.onChange(driver.id)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='vehicleId'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Vehicle *</FormLabel>
                <FormControl>
                  <VehicleInput
                    {...field}
                    fieldClassName='w-64 h-8'
                    notWorkingDay={form.getValues('date')}
                    onChange={vehicle => field.onChange(vehicle.id)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Card className='w-96'>
            <CardHeader>
              <CardTitle>Working Time</CardTitle>
              <CardDescription>
                Set the time range for this Shift. This will be included during
                autoscheduling.
              </CardDescription>
            </CardHeader>
            <CardContent className='flex flex-row gap-4'>
              <FormField
                control={form.control}
                name='workingTime.start'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Shift Start *</FormLabel>
                    <FormControl>
                      <DateInput
                        {...field}
                        type='time'
                        className='w-36 h-8'
                        onBlur={value => {
                          if (value > form.getValues(`workingTime.end`))
                            form.setValue(`workingTime.end`, value)
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='workingTime.end'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Shift End *</FormLabel>
                    <FormControl>
                      <DateInput
                        {...field}
                        type='time'
                        onBlur={value => {
                          if (value < form.getValues(`workingTime.start`))
                            form.setValue(`workingTime.start`, value)
                        }}
                        className='w-36 h-8'
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>
          </Card>
          <Card className='w-96'>
            <CardHeader>
              <div className='flex flex-row gap-2 place-items-end'>
                <CardTitle>Breaks</CardTitle>
                <Button
                  size='icon'
                  variant='outline'
                  type='button'
                  disabled={form.getValues('breaks').length >= 5}
                  onClick={() => {
                    const breaks = form.getValues('breaks')

                    const time =
                      breaks.length > 0
                        ? breaks[breaks.length - 1].end
                        : startOfDay(form.getValues('date'))

                    form.setValue('breaks', [
                      ...breaks,
                      {
                        start: time,
                        end: time
                      }
                    ])
                  }}
                  className='h-6 w-6'
                >
                  <TbPlus />
                </Button>
              </div>
              <CardDescription>
                Control breaks for this Shift. These parameters will be included
                during autoscheduling.
                <br />
                <br />
                Maximum 5 breaks allowed.
              </CardDescription>
            </CardHeader>
            {form.watch('breaks').length > 0 && (
              <CardContent className='flex flex-col gap-2'>
                {form.watch('breaks').map((_, index) => (
                  <div className='flex flex-row gap-2 place-items-end'>
                    <FormField
                      control={form.control}
                      name={`breaks.${index}.start`}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Break Start *</FormLabel>
                          <FormControl>
                            <DateInput
                              {...field}
                              type='time'
                              onBlur={value => {
                                if (
                                  value > form.getValues(`breaks.${index}.end`)
                                )
                                  form.setValue(`breaks.${index}.end`, value)
                              }}
                              className='w-36 h-8'
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name={`breaks.${index}.end`}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Break End *</FormLabel>
                          <FormControl>
                            <DateInput
                              {...field}
                              type='time'
                              onBlur={value => {
                                if (
                                  value <
                                  form.getValues(`breaks.${index}.start`)
                                )
                                  form.setValue(`breaks.${index}.start`, value)
                              }}
                              className='w-36 h-8'
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <Button
                      size='icon'
                      variant='outline'
                      type='button'
                      className='h-8 w-8'
                      onClick={() => {
                        form.setValue(
                          `breaks`,
                          form.getValues('breaks').filter((_, i) => index !== i)
                        )
                      }}
                    >
                      <TbTrash />
                    </Button>
                  </div>
                ))}
              </CardContent>
            )}
          </Card>
          <Button className='w-64 h-8' type='submit'>
            <TbPencil className='mr-1' />
            Save changes
          </Button>
        </form>
      )}
    </Form>
  )
}
export default ShiftForm
