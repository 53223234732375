import { useToast } from '@/components/ui/use-toast'
import tripsService from '@/services/trips.service'
import { IAPIError } from '@/types/common.type'
import { ISchedule, SettableScheduleStatus } from '@/types/trips.type'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useSetScheduleStatus = () => {
  const queryClient = useQueryClient()

  const { toast } = useToast()

  return useMutation({
    mutationFn: async ({
      schedule,
      forAllRiderTrips,
      status
    }: {
      schedule: ISchedule
      forAllRiderTrips?: boolean
      status: SettableScheduleStatus
    }) => {
      if (!schedule.id) throw new Error('Schedule not found.')

      await tripsService.setScheduleStatus(schedule.id, {
        status: status,
        forAllRiderTrips: forAllRiderTrips
      })

      return { schedule, status }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['trips'] })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}
