import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import DateInput from '@/components/ui/inputs/dateInput/DateInput'
import EnumInput from '@/components/ui/inputs/EnumInput'
import LocationInput from '@/components/ui/inputs/LocationInput'
import { IDriver, ISchemaDriver, SpecialAssistance } from '@/types/drivers.type'
import { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

interface IMiscellaneousTabProps {
  driver?: IDriver
  form: UseFormReturn<ISchemaDriver, any, undefined>
}

const MiscellaneousTab: FC<IMiscellaneousTabProps> = (
  props: IMiscellaneousTabProps
) => {
  return (
    <div className='flex flex-col gap-4'>
      <FormField
        control={props.form.control}
        name='socialSecurityNumber'
        render={({ field }) => (
          <FormItem>
            <FormLabel>Social Security Number</FormLabel>
            <FormControl>
              <Input
                placeholder='123-45-6789'
                className='w-64 h-8'
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={props.form.control}
        name='address'
        render={({ field }) => (
          <FormItem>
            <FormLabel>Address</FormLabel>
            <FormControl>
              <LocationInput fieldClassName='w-64 h-8' {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={props.form.control}
        name='notes'
        render={({ field }) => (
          <FormItem>
            <FormLabel>Notes</FormLabel>
            <FormControl>
              <Input className='w-64 h-8' {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={props.form.control}
        name='specialAssistance'
        render={({ field }) => (
          <FormItem>
            <FormLabel>Special Assistance</FormLabel>
            <FormControl>
              <EnumInput
                enum={SpecialAssistance}
                fieldClassName='w-64 h-8'
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={props.form.control}
        name='dateOfBirth'
        render={({ field }) => (
          <FormItem>
            <FormLabel>Date Of Birth</FormLabel>
            <FormControl>
              <DateInput type='date' className='w-64 h-8' {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  )
}
export default MiscellaneousTab
