import { IAuth, IAuthTokens } from '@/types/auth.type'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AuthState {
  auth?: IAuth | null
  authorized: boolean
}

const initialState: AuthState = {
  auth: null,
  authorized: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLogout: () => initialState,
    setRefresh: (state, action: PayloadAction<IAuthTokens>) => {
      if (!state.auth) return

      state.authorized = true
      state.auth.accessToken = action.payload.accessToken
      state.auth.refreshToken = action.payload.refreshToken
    },
    setLogin: (state, action: PayloadAction<IAuth>) => {
      state.auth = action.payload
      state.authorized = true
    }
  }
})

export const { setLogout, setRefresh, setLogin } = authSlice.actions
export default authSlice.reducer
