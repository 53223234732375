import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from '@/components/ui/hover-card'
import { useTheme } from '@/hooks/useTheme'
import { cn } from '@/lib/utils'
import { Theme } from '@/reducers/settingsSlice'
import { ISchemaSchedule } from '@/types/trips.type'
import { Map, Marker } from '@vis.gl/react-google-maps'
import { FC, useMemo } from 'react'
import { TbMap } from 'react-icons/tb'

interface IMapPreviewProps {
  schedule: ISchemaSchedule
  className?: string
}

const allowedMoveDistance = 1

const darkThemeStyles = [
  {
    elementType: 'geometry',
    stylers: [{ color: '#0A0A0A' }]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#0A0A0A' }]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{ color: '#B0B0B0' }]
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#A5A5A5' }]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#A5A5A5' }]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#1B1B1B' }]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#6B9A76' }]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#262626' }]
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#1A1A1A' }]
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#B0B0B0' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#3D3D3D' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#2C2C2C' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#D5B99C' }]
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: '#242424' }]
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#A5A5A5' }]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#11181B' }]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#B0B0B0' }]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#0A0A0A' }]
  }
]

const lightThemeStyles = [
  {
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#ffffff' }]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{ color: '#4a4a4a' }]
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#4a4a4a' }]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#4a4a4a' }]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#e6e6e6' }]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#4a4a4a' }]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#dcdcdc' }]
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#bfbfbf' }]
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#4a4a4a' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#e6e6e6' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#c9c9c9' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#4a4a4a' }]
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: '#e6e6e6' }]
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#4a4a4a' }]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#e6f0f5' }]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#4a4a4a' }]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#ffffff' }]
  }
]

const MapPreview: FC<IMapPreviewProps> = (props: IMapPreviewProps) => {
  const center = useMemo(
    () => ({
      lat: (props.schedule.from?.latitude + props.schedule.to?.latitude) / 2,
      lng: (props.schedule.from?.longitude + props.schedule.to?.longitude) / 2
    }),
    [props.schedule]
  )

  const theme = useTheme()
  return (
    props.schedule.from &&
    props.schedule.to && (
      <HoverCard>
        <HoverCardTrigger className={cn(props.className)}>
          <TbMap className='h-6 w-6' />
        </HoverCardTrigger>
        <HoverCardContent className='w-[600px] h-[600px]'>
          <Map
            className='w-full h-full'
            defaultCenter={center}
            defaultZoom={4}
            restriction={{
              latLngBounds: {
                north:
                  Math.max(
                    props.schedule.from.latitude,
                    props.schedule.to.latitude
                  ) + allowedMoveDistance,
                south:
                  Math.min(
                    props.schedule.from.latitude,
                    props.schedule.to.latitude
                  ) - allowedMoveDistance,
                west:
                  Math.min(
                    props.schedule.from.longitude,
                    props.schedule.to.longitude
                  ) - allowedMoveDistance,
                east:
                  Math.max(
                    props.schedule.from.longitude,
                    props.schedule.to.longitude
                  ) + allowedMoveDistance
              },
              strictBounds: true
            }}
            styles={theme === Theme.Light ? lightThemeStyles : darkThemeStyles}
            mapTypeId='roadmap'
            gestureHandling='greedy'
            disableDefaultUI={true}
            keyboardShortcuts={false}
          >
            <Marker
              position={{
                lat: props.schedule.from.latitude,
                lng: props.schedule.from.longitude
              }}
              icon={{
                url: '/from.svg',
                scaledSize: {
                  height: 30,
                  width: 27,
                  equals: () => true
                }
              }}
            />
            <Marker
              position={{
                lat: props.schedule.to.latitude,
                lng: props.schedule.to.longitude
              }}
              icon={{
                url: '/to.svg',
                scaledSize: {
                  height: 30,
                  width: 27,
                  equals: () => true
                }
              }}
            />
          </Map>
        </HoverCardContent>
      </HoverCard>
    )
  )
}
export default MapPreview
