import { ITimeRange } from '@/types/common.type'
import { IShift } from '@/types/shifts.type'
import { format } from 'date-fns'
import { api } from './axios.interceptor'

class ShiftsService {
  async getToday() {
    const response = await api.get<IShift[]>(
      `/shifts/today/${format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'")}`
    )

    return response
  }

  async getByTime(request: ITimeRange) {
    const response = await api.get<IShift[]>(`/shifts/byTime`, {
      params: request
    })

    return response
  }

  async getById(id: number) {
    const response = await api.get<IShift>(`/shifts/${id}`)

    return response
  }

  async create(request: IShift) {
    const response = await api.post<IShift>(`/shifts`, request)

    return response
  }

  async update(id: number, request: IShift) {
    const response = await api.patch<IShift>(`/shifts/${id}`, request)

    return response
  }

  async delete(id: number) {
    const response = await api.delete(`/shifts/${id}`)

    return response
  }
}

const shiftsService = new ShiftsService()

export default shiftsService
